import React, { ChangeEvent, useState, FormEvent, useEffect } from 'react';
import { useAppState } from '../../state';

import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
// import { ReactComponent as GoogleLogo } from './google-logo.svg';
import { InputLabel, Theme } from '@material-ui/core';
import IntroContainer from '../IntroContainer/IntroContainer';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import InviteForm from './InviteForm';
import Snackbar from '../Snackbar/Snackbar';

import { getDecryptedPWD } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  googleButton: {
    background: 'white',
    color: 'rgb(0, 94, 166)',
    borderRadius: '4px',
    border: '2px solid rgb(2, 122, 197)',
    margin: '1.8em 0 0.7em',
    textTransform: 'none',
    boxShadow: 'none',
    padding: '0.3em 1em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:hover': {
      background: 'white',
      boxShadow: 'none',
    },
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
  gutterBottom: {
    marginBottom: '1em',
  },
  passcodeContainer: {
    minHeight: '70px',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export function getPasscode() {
  const searchParams = new URLSearchParams(window.location.search);
  // const match = window.location.search.match(/pwd=(.*)&?/);
  // const pwd = match ? match[1] : ""
  const pwd = searchParams.get('pwd') || "";
  // const decryptedData = pwd ? CryptoJS.AES.decrypt(pwd, `${DECODE_KEY}`).toString(CryptoJS.enc.Utf8) : "";
  const decryptedData = getDecryptedPWD(pwd.replace(/ /gi, "+"));
  // console.log("decryptedData--", decryptedData);
  const decryptedPwd = decryptedData ? JSON.parse(decryptedData).passcode : "";
  const passcode = decryptedPwd ? decryptedPwd : window.sessionStorage.getItem('passcode');
  // console.log("passcode--", passcode);
  // const passcode = decData ? decData.toString(CryptoJS.enc.Utf8) : window.sessionStorage.getItem('passcode');

  return passcode;
}

export function fetchToken(
  name: string,
  room: string,
  passcode: string,
  create_room = true,
  create_conversation = process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true'
) {

  // console.log(name, room, passcode, create_room, create_conversation);
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/token-verify`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      user_identity: name,
      room_name: room,
      passcode,
      create_room,
      create_conversation
    }),
  });
}

export function verifyPasscode(meetingId: string, passcode: string) {
  return fetchToken('temp-name', meetingId, passcode, false /* create_room */, false /* create_conversation */).then(
    async res => {
      const jsonResponse = await res.json();
      if (res.status !== 200) {
        return { isValid: false, error: jsonResponse.error?.message, explanation: jsonResponse.error?.explanation };
      }

      if (res.ok && jsonResponse.token) {
        // window.sessionStorage.setItem('m_key', jsonResponse.meeting_key);
        return { isValid: true, apiKey: jsonResponse.meeting_key };
      }
    }
  );
}

export async function fetchMeeting(meetingId: string, apiKey: string) {
  const m_key = apiKey ? apiKey : "";

  return fetch(`${process.env.REACT_APP_API_BASE_URL}/api/meeting/${meetingId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'X-API-KEY': m_key
    },
  });
}

export function getMeetingData(meetingId: string, apiKey: string) {
  return fetchMeeting(meetingId, apiKey).then(
    async res => {
      const jsonResponse = await res.json();
      // console.log("MEETING-Res--", jsonResponse);
      if (res.status === 401) {
        return { isValid: false, error: jsonResponse.error?.message, passcode: "", meeting: null };
      }

      if (res.ok && jsonResponse && jsonResponse.passcode) {
        // if (jsonResponse.is_archived || jsonResponse.is_deleted) {
        //   return { isValid: false, error: "Unable to connect meeting", passcode: "", meeting: null };
        // } else {
        return { isValid: true, passcode: jsonResponse.passcode, meeting: jsonResponse };
        // }
      } else {
        return { isValid: false, error: "Unable to connect meeting", passcode: "", meeting: null };
      }
    }
  );
}

export default function InvitePage() {
  const classes = useStyles();
  // const { signIn, user, isAuthReady, meetingError } = useAppState();

  const { meetingId } = useParams<{ meetingId?: string }>();

  const history = useHistory();
  const [isSuccess, setIsSuccess] = useState(false);
  const [meeting, setMeeting] = useState<any | null>(null);
  const [meetingError, setMeetingError] = useState<Error | null>(null);
  const [apiKey, setApiKey] = useState('');

  useEffect(() => {
    const passcode = getPasscode();
    // console.log("passcode", passcode)
    // console.log("meetingId", meetingId)

    if (meetingId && passcode) {
      verifyPasscode(meetingId, passcode)
        .then(verification => {
          // console.log("verification", verification)
          if (verification?.isValid) {
            setApiKey(verification.apiKey)
            getMeetingData(meetingId, verification.apiKey)
              .then(meetingData => {
                // console.log("meetingData-verify", meetingData);
                if (meetingData && meetingData?.isValid && meetingData.meeting) {
                  setMeeting(meetingData.meeting);
                } else {
                  // setMeetingError(new Error(meetingData?.error))
                }
              })
          } else {
            // setMeetingError(new Error(verification?.explanation || verification?.error))
          }
        });
    }
  }, [history]);


  // const location = useLocation<{ from: Location }>();
  // const [countryCode, setCountryCode] = useState('');
  // const [phoneNumber, setPhoneNumber] = useState('');

  // const [authError, setAuthError] = useState<Error | null>(null);
  // const [isLogging, setIsLogging] = useState(false);

  // const isAuthEnabled = Boolean(true);

  // useEffect(() => {
  //   if (meetingError) {
  //     setAuthError(meetingError);
  //   }
  // }, [meetingError])

  // const login = () => {
  //   setAuthError(null);
  //   setIsLogging(true);
  //   signIn?.(meetingId, passcode)
  //     .then(() => {
  //       setIsLogging(false);
  //       // console.log("FROM", location?.state?.from);
  //       // history.replace(location?.state?.from || { pathname: `/meeting/${meetingId}` });
  //       history.replace({ pathname: `/meeting/${meetingId}` });
  //     })
  //     .catch(err => {
  //       setIsLogging(false);
  //       setAuthError(err);
  //     });
  // };

  const handleSubmit = () => {
    setIsSuccess(true)
    // history.replace('/');
  };

  // if (user || !isAuthEnabled) {
  //   history.replace('/');
  // }

  // if (!isAuthReady) {
  //   return null;
  // }

  return (
    <IntroContainer>
      <Snackbar
        variant="info"
        headline="Success"
        message="Call invitation sent..."
        open={isSuccess}
        handleClose={() => setIsSuccess(false)}
      />
      <InviteForm onSuccess={() => handleSubmit()} apiKey={apiKey} topic={meeting?.topic || ""} />
    </IntroContainer >
  );
}
