import React from 'react';
const css = String.raw;

export default function MoreIcon(props: { className?: string }) {
  return (
    <svg className={props.className} width="32" height="32"  viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
      <style type="text/css">
        {css`
          .stMoreIcon{fill:#FFFFFF;}
        `}
      </style>
      <g>
        <g>
          <circle className="stMoreIcon" cx="9.7" cy="16" r="1.7" />
          <circle className="stMoreIcon" cx="16" cy="16" r="1.7" />
          <circle className="stMoreIcon" cx="22.3" cy="16" r="1.7" />
        </g>
      </g>
    </svg>
  );
}
