import React, { useEffect } from 'react';
import { useAppState } from '../../state';

import DialecticaLogo from '../../icons/DialecticaLogo';
import VideoLogo from '../IntroContainer/VideoLogo';
import { Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // background: 'rgb(40, 42, 43)',
    background: '#191b1d',
    height: '100%',
  },
  container: {
    position: 'relative',
    flex: '1',
  },
  innerContainer: {
    display: 'flex',
    width: '888px',
    height: '379px',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
    overflow: 'hidden',
    position: 'relative',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: 'auto',
      width: 'calc(100% - 40px)',
      margin: 'auto',
      maxWidth: '400px',
    },
  },
  swooshContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundImage: Swoosh,
    // backgroundSize: 'cover',
    background: '#10416D',
    width: '296px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100px',
      backgroundPositionY: '140px',
    },
  },
  logoContainer: {
    position: 'absolute',
    width: '210px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      width: '90%',
      textAlign: 'initial',
      '& svg': {
        height: '64px',
      },
    },
  },
  twilioLogo: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: '20px',

  },
  content: {
    background: 'white',
    width: '100%',
    padding: '4em',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '2em',
    },
  },
  title: {
    color: 'white',
    margin: '1em 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      fontSize: '1.1rem',
    },
  },
  dialecticaLogo: {
    top: '10px',
    left: '12px',
    position: 'absolute',
    height: '40px',
    // enableBackground: 'new 0 0 254 61.9'
  },
  topbar: {
    height: '60px',
    width: '100%',
    backgroundColor: 'white',
    top: '0px',
    position: 'absolute'
  },
  bodyText: {
    marginBottom: '1em',
    whiteSpace: 'pre-line'
  },
  googleButton: {
    background: 'white',
    color: 'rgb(0, 94, 166)',
    borderRadius: '4px',
    border: '2px solid rgb(2, 122, 197)',
    margin: '1.8em 0 0.7em',
    textTransform: 'none',
    boxShadow: 'none',
    padding: '0.3em 1em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:hover': {
      background: 'white',
      boxShadow: 'none',
    },
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
  gutterBottom: {
    marginBottom: '1em',
  },
  passcodeContainer: {
    minHeight: '70px',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default function TimeoutPage() {
  const classes = useStyles();
  const { signOut, meeting } = useAppState();

  useEffect(() => {
    window?.gtag('event', 'Redirect To Timeout', { 'meetingId': meeting?.meeting_id || '' });
    signOut?.();
  }, []);

  return (
    <div className={classes.background}>
      <DialecticaLogo className={classes.dialecticaLogo} />
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.swooshContainer}>
            <div className={classes.logoContainer}>
              <VideoLogo />
              <Typography variant="h6" className={classes.title}> Meeting Timeout </Typography>
            </div>
          </div>
          <div className={classes.content}>
            <Typography variant="h5" className={classes.gutterBottom}>
              Hi!
            </Typography>
            <Typography variant="body1" className={classes.bodyText}>
              Sorry about this, you have landed on this page due to a timeout or network connectivity issue.
            </Typography>
            <Typography variant="body1" className={classes.bodyText}>
              Please click on the meeting link in your invite to rejoin, or dial in with number provided.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
