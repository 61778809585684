import React, { ChangeEvent, useState, FormEvent, useEffect } from 'react';
import { useAppState } from '../../state';

import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
// import { ReactComponent as GoogleLogo } from './google-logo.svg';
import { InputLabel, Theme } from '@material-ui/core';
import IntroContainer from '../IntroContainer/IntroContainer';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  googleButton: {
    background: 'white',
    color: 'rgb(0, 94, 166)',
    borderRadius: '4px',
    border: '2px solid rgb(2, 122, 197)',
    margin: '1.8em 0 0.7em',
    textTransform: 'none',
    boxShadow: 'none',
    padding: '0.3em 1em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:hover': {
      background: 'white',
      boxShadow: 'none',
    },
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
  gutterBottom: {
    marginBottom: '1em',
  },
  passcodeContainer: {
    minHeight: '70px',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default function LoginPage() {
  const classes = useStyles();
  const { signIn, user, isAuthReady, meetingError } = useAppState();
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const [passcode, setPasscode] = useState('');
  const [meetingId, setMeetingId] = useState('');
  const [authError, setAuthError] = useState<Error | null>(null);
  const [isLogging, setIsLogging] = useState(false);

  const isAuthEnabled = Boolean(true);

  useEffect(() => {
    if (meetingError) {
      setAuthError(meetingError);
    }
  }, [meetingError])

  const login = () => {
    setAuthError(null);
    setIsLogging(true);
    signIn?.(meetingId, passcode)
      .then(() => {
        setIsLogging(false);
        // console.log("FROM", location?.state?.from);
        // history.replace(location?.state?.from || { pathname: `/meeting/${meetingId}` });
        history.replace({ pathname: `/meeting/${meetingId}` });
      })
      .catch(err => {
        setIsLogging(false);
        setAuthError(err);
      });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login();
  };

  if (user || !isAuthEnabled) {
    history.replace('/');
  }

  if (!isAuthReady) {
    return null;
  }

  return (
    <IntroContainer>
      {/* {process.env.REACT_APP_SET_AUTH === 'firebase' && (
        <>
          <Typography variant="h5" className={classes.gutterBottom}>
            Sign in to join a room
          </Typography>
          <Typography variant="body1">Sign in using your Twilio Google Account</Typography>
          <Button variant="contained" className={classes.googleButton} onClick={login} startIcon={<GoogleLogo />}>
            Sign in with Google
          </Button>
        </>
      )} */}

      {/* {process.env.REACT_APP_SET_AUTH === 'passcode' && (
        <> */}
      <Typography variant="h6" className={classes.gutterBottom}>
        Enter details to join meeting
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12}>
            <div className={classes.passcodeContainer}>
              <InputLabel shrink htmlFor="input-meetingId">
                Meeting ID
              </InputLabel>
              <TextField
                id="input-meetingId"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setMeetingId(e.target.value)}
                type="text"
                variant="outlined"
                size="small"
                fullWidth={true}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.passcodeContainer}>
              <InputLabel shrink htmlFor="input-passcode">
                Passcode
              </InputLabel>
              <TextField
                id="input-passcode"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setPasscode(e.target.value)}
                type="password"
                variant="outlined"
                size="small"
                fullWidth={true}
              />
            </div>
          </Grid>
          <div>
            {authError && (
              <Typography variant="caption" className={classes.errorMessage}>
                <ErrorOutlineIcon />
                {authError.message}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!passcode.length || isLogging}
            className={classes.submitButton}
          >
            Submit
          </Button>
        </Grid>
      </form>
      {/* </>
      )} */}
    </IntroContainer >
  );
}
