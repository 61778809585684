import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import DisclaimerScreen from './DisclaimerScreen/DisclaimerScreen';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
// import CryptoJS from 'crypto-js';

export enum Steps {
  roomNameStep,
  disclaimerStep,
  deviceSelectionStep,
}

export default function PreJoinScreens() {
  const { user, meeting } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { meetingId } = useParams<{ meetingId?: string }>();
  const [step, setStep] = useState(Steps.roomNameStep);

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    // console.log("URLNAME--", meetingId);
    if (meetingId) {
      try {
        // const decData = CryptoJS.enc.Base64.parse(URLRoomName);
        // console.log("tv:: ", decData.toString(CryptoJS.enc.Utf8));
        // setRoomName(decData.toString(CryptoJS.enc.Utf8));
        // console.log("MEETING-DATA: ",meeting);
        setRoomName(meetingId);
        // console.log("URLNAME--",URLRoomName);
        if (user?.displayName) {
          setStep(Steps.deviceSelectionStep);
        }
      } catch (e) {
        console.log("ERROR--", e)
        setMediaError(new Error('InvalidURL'));
      }
    }
  }, [user, meetingId]);

  const isValidateParticipantName = () => {
    const regex = /^[a-zA-Z0-9\s.]+$/;
    return regex.test(name);
  }

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      window.history.replaceState(null, '', window.encodeURI(`/meeting/${roomName}${window.location.search || ''}`));
    }
    if (isValidateParticipantName()) {
      // console.log("MEETING----DATA",meeting)
      if(meeting && meeting && meeting.show_disclaimer && meeting.disclaimer_text !== "") {
        setStep(Steps.disclaimerStep);
      } else {
        setStep(Steps.deviceSelectionStep);
      }

    } else {
      alert('Name should not contains any special characters');
    }
  };

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          roomName={roomName}
          topicName={meeting?.topic}
          setName={setName}
          setRoomName={setRoomName}
          handleSubmit={handleSubmit}
        />
      )}
      
      {(step === Steps.disclaimerStep) && (
        <DisclaimerScreen
          disclaimer={meeting.disclaimer_text}
          setStep={setStep}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={name} roomName={roomName} topicName={meeting?.topic} setStep={setStep} />
      )}
    </IntroContainer>
  );
}
