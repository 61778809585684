import React, { useState } from 'react';
import { Typography, makeStyles, Grid, Button, Theme, Checkbox } from '@material-ui/core';
import { Steps } from '../PreJoinScreens';

const useStyles = makeStyles((theme: Theme) => ({
    gutterBottom: {
        marginBottom: '1em',
        textAlign: 'center'
    },
    inputContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '1.5em 0 3.5em',
        '& div:not(:last-child)': {
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '1.5em 0 2em',
        },
    },
    textFieldContainer: {
        width: '100%',
    },
    continueButton: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    confirmLabel: {
        display: 'flex',
        '& .MuiCheckbox-root': {
            paddingLeft: '0px'
        },
        '& span': {
            fontSize: '0.842rem',
            display: 'flex',
            alignItems: 'center'
        }
    },
    joinButtons: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            width: '100%',
            '& button': {
                margin: '0.5em 0',
            },
        },
    },
    bodyText: {
        marginBottom: '1em',
        whiteSpace: 'pre-line',
        overflowY: 'auto',
        maxHeight: '150px',
    }
}));

interface DisclaimerScreenProps {
    disclaimer: string;
    setStep: (step: Steps) => void;
}

export default function DisclaimerScreen({ disclaimer, setStep }: DisclaimerScreenProps) {
    const classes = useStyles();
    const [confirm, setConfirm] = useState(false);

    return (
        <>
            <Typography variant="h5" className={classes.gutterBottom}>
                Disclaimer
            </Typography>
            <Typography variant="body1" className={classes.bodyText}>
                {disclaimer}
            </Typography>
            {/* <Grid container justifyContent="center">
                <Grid item md={4} sm={12} xs={12}> */}
            <div className={classes.confirmLabel}>
                <Checkbox checked={confirm} onChange={() => { setConfirm(!confirm) }} name="confirmDeclaration" color="primary" />
                <span>I hereby confirm that I have read and understood the above statement</span>
            </div>
            <div className={classes.joinButtons}>
                <Button
                    disabled={!confirm}
                    variant="contained"
                    color="primary"
                    data-cy-join-now
                    onClick={confirm ? () => setStep(Steps.deviceSelectionStep) : () => { }}
                >
                    Continue To Meeting
                </Button>
            </div>
            {/* </Grid>
            </Grid> */}
        </>
    );
}
