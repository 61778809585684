import React from 'react';
const css = String.raw;

export default function MicIcon(props: { className?: string }) {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 12 17">
    //   <path
    //     fill="#707578"
    //     fillRule="evenodd"
    //     d="M11.389 6.667c.276 0 .5.224.5.5 0 3.114-2.396 5.67-5.445 5.923v2.632c0 .276-.223.5-.5.5-.245 0-.45-.177-.491-.41l-.009-.09V13.09C2.395 12.836 0 10.281 0 7.167c0-.276.224-.5.5-.5s.5.224.5.5c0 2.73 2.214 4.944 4.944 4.944 2.731 0 4.945-2.214 4.945-4.944 0-.276.224-.5.5-.5zM5.944 0C7.51 0 8.778 1.268 8.778 2.833v4.334C8.778 8.73 7.51 10 5.944 10 4.38 10 3.111 8.73 3.111 7.167V2.833C3.111 1.268 4.38 0 5.944 0zm0 1c-1.012 0-1.833.82-1.833 1.833v4.334C4.111 8.179 4.933 9 5.944 9c1.013 0 1.834-.82 1.834-1.833V2.833C7.778 1.82 6.958 1 5.944 1z"
    //   />
    // </svg>
    
    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" className={props.className}>
      <style type="text/css">
        {css`
          .stMicIcon{fill:#FFFFFF;}
        `}
      </style>
      <g>
        <path className='stMicIcon' d="M16,24.5L16,24.5c-3.9,0-7-3.1-7-7v-10c0-3.9,3.1-7,7-7h0c3.9,0,7,3.1,7,7v10C23,21.4,19.9,24.5,16,24.5z" />
        <path className='stMicIcon' d="M16,28c-5.8,0-10.5-4.7-10.5-10.5V16h1v1.5c0,5.2,4.3,9.5,9.5,9.5s9.5-4.3,9.5-9.5V16h1v1.5
		C26.5,23.3,21.8,28,16,28z"/>
        <rect className='stMicIcon' x="7.5" y="30.5" width="17" height="1" />
        <rect className='stMicIcon' x="15.5" y="28" width="1" height="3" />
      </g>
    </svg>
  );
}
