import React, { PropsWithChildren, ChangeEvent, useState, useCallback, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';

import { InputLabel, Theme } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import InviteForm from './InviteForm';

const useStyles = makeStyles((theme: Theme) => ({
  textContainer: {
    minHeight: '70px',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
}))

interface InviteDialogProps {
  open: boolean;
  onClose(): void;
  onSuccess(): void;
  apiKey: string;
}

function InviteDialog({ open, onClose, onSuccess, apiKey }: PropsWithChildren<InviteDialogProps>) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">

      {/* <DialogTitle>Invite Participant Using Phone Number</DialogTitle> */}

      {/* <Divider /> */}

      <DialogContent>
        <InviteForm onSuccess={() => onSuccess()} apiKey={apiKey} />
      </DialogContent>

      {/* <Divider />

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="button"
            disabled={!phoneNumber.length || inProgress}
            className={classes.submitButton}
            onClick={()=>{}}
          >
            Invite
          </Button>
          <Button
            variant="contained"
            color="secondary"
            type="button"
            className={classes.submitButton}
            onClick={onClose}
          >
            Cancel
          </Button>
        </DialogActions> */}

    </Dialog>
  );
}

export default InviteDialog;
