import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import MicIcon from '../../../icons/MicIcon';
import MicOffIcon from '../../../icons/MicOffIcon';
import MicMuteIcon from '../../../icons/MicMuteIcon';

import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useLocalMediaMuted from '../../../hooks/useLocalMediaMuted/useLocalMediaMuted';

import { useAppState } from '../../../state';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';

import { LocalAudioTrack } from 'twilio-video';

export default function ToggleAudioButton(props: { disabled?: boolean; className?: string }) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks, room } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');

  const audioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const isLocalAudioMuted = useLocalMediaMuted(audioTrack);

  console.log("isLocalAudioMuted::::",isLocalAudioMuted);

  const refreshInactiveMedia = () => {
    if(room && isLocalAudioMuted) {
      room.refreshInactiveMedia();
    }
  };

  const { meeting } = useAppState();
  const [isOpen, setIsOpen] = useState(false);

  const toggleAudio = () => {
    if (!isAudioEnabled && meeting && Boolean(meeting.isChaperoning)) {
      setIsOpen(true);
    } else {
      toggleAudioEnabled();
    }
  };

  const closeDialog = () => {
    setIsOpen(false);
    toggleAudioEnabled();
  };

  const cancelToggleAudio = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={cancelToggleAudio} fullWidth={true} maxWidth="xs">
        <DialogTitle>Warning!</DialogTitle>
        <Divider />
        <DialogContent>
          Turning to unmute will reveal your presence.
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="button"
            // className={classes.submitButton}
            onClick={closeDialog}
          >
            Ok
          </Button>
          <Button
            variant="contained"
            color="secondary"
            type="button"
            // className={classes.submitButton}
            onClick={cancelToggleAudio}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Tooltip
        title={`Microphone: ${!hasAudioTrack ? 'No Audio' : (isAudioEnabled || isLocalAudioMuted) ? 'Unmute' : 'Mute'}`}
        placement="top"
        PopperProps={{ disablePortal: true }}
        style={{ cursor: (!hasAudioTrack || props.disabled) ? 'not-allowed' : 'pointer' }}
      >
        <span>
          <Button
            className={props.className}
            onClick={isLocalAudioMuted ? refreshInactiveMedia : toggleAudio}
            disabled={!hasAudioTrack || props.disabled}
            startIcon={isAudioEnabled ? isLocalAudioMuted ? <MicMuteIcon /> : <MicIcon className="micIcon" /> : <MicOffIcon />}
            data-cy-audio-toggle
          >
            {/* {!hasAudioTrack ? 'No Audio' : isAudioEnabled ? 'Mute' : 'Unmute'} */}
          </Button>

        </span>
      </Tooltip>
    </>
  );
}
