import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

import ParticipantIcon from '../../../icons/ParticipantIcon';

import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useParticipants from '../../../hooks/useParticipants/useParticipants';
import useParticipantWindowContext from '../../../hooks/useParticipantWindowContext/useParticipantWindowContext';

export const ANIMATION_DURATION = 700;

const useStyles = makeStyles({
  iconContainer: {
    position: 'relative',
    display: 'flex',
  },
  activeChat: {
    '& svg': {
      filter: 'invert(27%) sepia(11%) saturate(2879%) hue-rotate(80deg) brightness(98%) contrast(100%)'
    }
  },
  circle: {
    width: '10px',
    height: '10px',
    backgroundColor: '#5BB75B',
    borderRadius: '50%',
    position: 'absolute',
    top: '-3px',
    left: '13px',
    opacity: 0,
    transition: `opacity ${ANIMATION_DURATION * 0.5}ms ease-in`,
  },
  hasUnreadMessages: {
    opacity: 1,
  },
  ring: {
    border: '3px solid #5BB75B',
    borderRadius: '30px',
    height: '14px',
    width: '14px',
    position: 'absolute',
    left: '11px',
    top: '-5px',
    opacity: 0,
  },
  animateRing: {
    animation: `$expand ${ANIMATION_DURATION}ms ease-out`,
    animationIterationCount: 1,
  },
  '@keyframes expand': {
    '0%': {
      transform: 'scale(0.1, 0.1)',
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1.4, 1.4)',
      opacity: 0,
    },
  },
});

export default function ToggleParticipantButton() {
  const classes = useStyles();
  const [shouldAnimate, setShouldAnimate] = useState(false);

  const { setIsChatWindowOpen } = useChatContext();
  const { setIsBackgroundSelectionOpen } = useVideoContext();
  const participants = useParticipants();
  const { isParticipantWindowOpen, setIsParticipantWindowOpen } = useParticipantWindowContext();

  const toggleParticipantWindow = () => {
    setIsParticipantWindowOpen(!isParticipantWindowOpen);
    setIsChatWindowOpen(false);
    setIsBackgroundSelectionOpen(false);
  };

  useEffect(() => {
    if (shouldAnimate) {
      setTimeout(() => setShouldAnimate(false), ANIMATION_DURATION);
    }
  }, [shouldAnimate]);

  // useEffect(() => {
  //   if (conversation && !isChatWindowOpen) {
  //     const handleNewMessage = () => setShouldAnimate(true);
  //     conversation.on('messageAdded', handleNewMessage);
  //     return () => {
  //       conversation.off('messageAdded', handleNewMessage);
  //     };
  //   }
  // }, [conversation, isChatWindowOpen]);

  if (participants.length === 0) return null;

  return (
    <Tooltip
      title="Participants"
      placement="top"
      PopperProps={{ disablePortal: true }}
      style={{ cursor: participants.length === 0 ? 'not-allowed' : 'pointer' }}
    >
      <span>
        <Button
          data-cy-participants-button
          onClick={toggleParticipantWindow}
          disabled={participants.length === 0}
          startIcon={
            <div className={clsx(classes.iconContainer, { [classes.activeChat]: isParticipantWindowOpen })}>
              <ParticipantIcon />
            </div>
          }
        >
          {/* Participants */}
        </Button>

      </span>
    </Tooltip>
  );
}
