import React from 'react';
const css = String.raw;

export default function VideoOffIcon() {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    //   <g fill="none" fillRule="evenodd">
    //     <path
    //       fill="#707578"
    //       d="M10.503.85c.32 0 .611.125.827.329l-.696.697c-.025-.018-.053-.032-.084-.038l-.047-.006H1.207c-.102 0-.19.071-.216.165l-.008.059v7.866c0 .101.07.189.165.215l.059.008h1.156l-.983.983h-.173c-.624 0-1.139-.476-1.2-1.083L0 9.922V2.056C0 1.433.476.917 1.084.856l.123-.007h9.296zm5.49 1.124l.007.082v7.866c0 .337-.328.566-.635.47l-.076-.03-3.58-1.79v1.35c0 .623-.476 1.138-1.083 1.2l-.123.006H4.16l.984-.983h5.359c.101 0 .189-.07.215-.164l.008-.06V4.564l.983-.982.353-.353 3.227-1.612c.301-.15.652.04.704.358zm-.977.876L11.71 4.504v2.967l3.307 1.654V2.85z"
    //       transform="translate(4 6)"
    //     />
    //     <path
    //       fill="red"
    //       fillRule="nonzero"
    //       d="M13.06.144c.192-.192.503-.192.695 0 .171.17.19.436.057.627l-.057.068-12.29 12.29c-.192.193-.503.193-.695 0-.171-.17-.19-.435-.057-.626l.057-.069L13.06.144z"
    //       transform="translate(4 6)"
    //     />
    //   </g>
    // </svg>

    <svg height="32" width="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
      <style type="text/css">
        {css`
          .stVideoOffIcon0{fill:#FF0000;}
          .stVideoOffIcon1{fill:#FFFFFF;}
        `}
      </style>
      <g>
        <path className="stVideoOffIcon0" d="M16,32L16,32C7.2,32,0,24.8,0,16v0C0,7.2,7.2,0,16,0h0c8.8,0,16,7.2,16,16v0C32,24.8,24.8,32,16,32z" />
        <g>
          <path className="stVideoOffIcon1" d="M18.3,24.2h-7.5c-3.2,0-5.8-2.6-5.8-5.8v-5.5c0-1.6,1.3-2.9,2.9-2.9h10.4c1.6,0,2.9,1.3,2.9,2.9v8.5
			C21.2,22.9,19.9,24.2,18.3,24.2z M7.9,11.3c-0.8,0-1.5,0.7-1.5,1.5v5.5c0,2.4,2,4.4,4.4,4.4h7.5c0.8,0,1.5-0.7,1.5-1.5v-8.5
			c0-0.8-0.7-1.5-1.5-1.5H7.9z"/>
          <path className="stVideoOffIcon1" d="M26.4,24.9l-0.8-0.1c-3.3-0.4-5.8-3.8-5.8-8.1c0-4.2,2.5-7.7,5.8-8.1l0.8-0.1V24.9z M25,10.2
			c-2.2,0.7-3.7,3.4-3.7,6.5c0,3.1,1.6,5.8,3.7,6.5V10.2z"/>
        </g>
        <rect className="stVideoOffIcon1" x="3.7" y="17.6" transform="matrix(0.8628 -0.5056 0.5056 0.8628 -6.9822 10.9537)" width="26" height="1.4" />
        <rect className="stVideoOffIcon0" x="3.6" y="16.2" transform="matrix(0.8619 -0.5071 0.5071 0.8619 -6.2785 10.6616)" width="25.7" height="1.4" />
      </g>
    </svg>
  );
}
