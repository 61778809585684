import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { removeIdentityMasking } from '../../../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    messageInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1.425em 0 0.083em',
      fontSize: '12px',
      color: '#606B85',
    },
  })
);

interface MessageInfoProps {
  author: string;
  dateCreated: string;
  isLocalParticipant: boolean;
}

// const removeMasking = (identity: string) => {
//   let title = "";
//   if (identity.startsWith('+')) {
//     title = identity.split('-')[0];
//   } else {
//     title = identity.split('###')[0];
//   }
//   return title;
// }

export default function MessageInfo({ author, dateCreated, isLocalParticipant }: MessageInfoProps) {
  const classes = useStyles();

  return (
    <div className={classes.messageInfoContainer}>
      <div>{isLocalParticipant ? `${removeIdentityMasking(author)} (You)` : removeIdentityMasking(author)}</div>
      <div>{dateCreated}</div>
    </div>
  );
}
