import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';

import { Device, Call } from '@twilio/voice-sdk';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

type VoiceContextType = {
  connect: (token: string) => void;
  deviceClient: Device | null
};

export const VoiceContext = createContext<VoiceContextType>(null!);

export const VoiceProvider: React.FC = ({ children }) => {
  const { onError } = useVideoContext();
  const [deviceClient, setDeviceClient] = useState<Device | null>(null);
  // const [call, setCall] = useState<Call>();

  useEffect(() => {
    return () => {
      if (deviceClient) {
        deviceClient.destroy()
      }
    }
  }, [])

  // function updateAllAudioDevices() {
  //   if (deviceClient) {
  //     updateDevices(speakerDevices, deviceClient.audio?.speakerDevices.get());
  //     updateDevices(ringtoneDevices, deviceClient.audio?.ringtoneDevices.get());
  //   }
  // }

  // function updateDevices(selectEl, selectedDevices) {
  //   selectEl.innerHTML = "";

  //   deviceClient.audio?.availableOutputDevices.forEach(function (device, id) {
  //     var isActive = selectedDevices.size === 0 && id === "default";

  //     selectedDevices.forEach(function (device: any) {
  //       if (device.deviceId === id) {
  //         isActive = true;
  //       }
  //     });

  //     var option = document.createElement("option");
  //     option.label = device.label;
  //     option.setAttribute("data-id", id);

  //     if (isActive) {
  //       option.setAttribute("selected", "selected");
  //     }

  //     selectEl.appendChild(option);
  //   });
  // }

  const connect = (token: string) => {
    try {
      // console.log("TOKEN::",token);
      const device = new Device(token, {
        logLevel: 1,
        // Set Opus as our preferred codec.
        // Opus generally performs better, requiring less bandwidth and providing better audio quality in restrained network conditions.
        codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
        enableImprovedSignalingErrorPrecision: true
      })

      console.log("voiceDeviceSuccess", device);

      setDeviceClient(device);
      //@ts-ignore
      window.deviceClient = device;

      // device.on("registered", function () {
      //   console.log("Twilio.Device Ready to make and receive calls!");
      //   // callControlsDiv.classList.remove("hide");
      //   setDeviceClient(device);
      //   //@ts-ignore
      //   window.deviceClient = device;
      // });

      device.on("error", function (error) {
        console.log("deviceClient-error", error);
        // console.log("Twilio.Device Error: " + error.message);
        // setDeviceClient(null);
        //@ts-ignore
        // window.deviceClient = null;
      });

      // Device must be registered in order to receive incoming calls
      // device.register();
    } catch (deviceError) {
      console.log("voiceDeviceError", deviceError);
      onError(new Error("There was a problem connecting to Voice Device service."));
    }
  }

  async function makeOutgoingCall(toNumber: string) {
    var params = {
      To: toNumber,
    };

    if (deviceClient) {
      console.log(`Attempting to call ${params.To} ...`);

      try {
        // Twilio.Device.connect() returns a Call object
        const voiceCall = await deviceClient.connect({ params });
        //@ts-ignore
        window.voiceCall = call;
        return voiceCall;
      } catch (error) {
        console.log("Unable to make call.");
        console.log(error);
        return null;
      }
      // // add listeners to the Call
      // // "accepted" means the call has finished connecting and the state is now "open"
      // call.on("accept", updateUIAcceptedOutgoingCall);
      // call.on("disconnect", updateUIDisconnectedOutgoingCall);
      // call.on("cancel", updateUIDisconnectedOutgoingCall);

      // outgoingCallHangupButton.onclick = () => {
      //   log("Hanging up ...");
      //   call.disconnect();
      // };
    } else {
      console.log("Unable to make call.");
      return null;
    }
  }

  return (
    <VoiceContext.Provider
      value={{ connect, deviceClient }}
    >
      {children}
    </VoiceContext.Provider>
  );
};
