import { useContext } from 'react';
import { ParticipantWindowContext } from '../../components/ParticipantWindowProvider';

export default function useParticipantWindowContext() {
  const context = useContext(ParticipantWindowContext);
  if (!context) {
    throw new Error('useParticipantWindowContext must be used within a ParticipantWindowProvider');
  }
  return context;
}
