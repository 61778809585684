import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import ParticipantWindowHeader from './ParticipantWindowHeader/ParticipantWindowHeader';
import ParticipantList from './ParticipantList/ParticipantList';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useParticipantWindowContext from '../../hooks/useParticipantWindowContext/useParticipantWindowContext';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    windowContainer: {
      background: '#FFFFFF',
      zIndex: 11,
      display: 'flex',
      flexDirection: 'column',
      padding: '15px 0px',
      borderLeft: '1px solid #E4E7E9',
      borderTop: '1px solid #E4E7E9',
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 100,
      },
    },
    hide: {
      display: 'none',
    },
    searchbar: {
      margin: '0 1em',
      display: 'flex',
      marginTop: '15px',
      border: 'solid 1px gray',
      borderRadius: '6px',
      minHeight: '28px'
    }
  })
);

export default function ParticipantWindow() {
  const classes = useStyles();

  const { isParticipantWindowOpen, setIsParticipantWindowOpen } = useParticipantWindowContext();
  
  const participants = useParticipants();
  const filteredParticipants = participants.filter(p => !p.identity.split('###')[1].startsWith('CP##'))

  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;

  let allParticipants = [];
  allParticipants = [...filteredParticipants, localParticipant];

  const [searchField, setSearchField] = useState("");

  const filteredPersons = allParticipants.filter(
    participant => {
      return (
        participant
          .identity
          .split('###')[0]
          .toLowerCase()
          .includes(searchField.toLowerCase())
      );
    }
  );

  const handleChange = (e: any) => {
    setSearchField(e.target.value);
  };

  return (
    <aside className={clsx(classes.windowContainer, { [classes.hide]: !isParticipantWindowOpen })}>
      <ParticipantWindowHeader closeWindow={() => setIsParticipantWindowOpen(false)} />
      <input className={classes.searchbar} value={searchField} onChange={handleChange} placeholder="Search" />
      <ParticipantList participants={filteredPersons} />
    </aside>
  );
}
