import { Button } from '@material-ui/core';
import { useEffect } from 'react';

const MeetingLeaveDialog = props => {
  const { open, handleClose = () => {}, handleStayMeeting = () => {} } = props;
  useEffect(() => {
    if (open === true) {
      const audio = new Audio('/sound/notification_sound.wav');
      audio.play().catch(error => {
        console.log('Error playing sound:', error);
      });
    }
  }, [open]);
  return (
    <>
      {open === true ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '600px',
          }}
        >
          <div
            style={{
              position: 'relative',
              zIndex: '999',
              color: 'black',
              backgroundColor: 'white',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '20px',
            }}
          >
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'end',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
              >
                {/* <div style={{ backgroundColor: 'gray', borderRadius: '10px', padding: '5px 10px 5px 10px' }}>X</div> */}
              </div>
              <div style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                {"It looks like you're the only participant. Would you like to stay in the meeting?"}
              </div>
            </div>
            <div style={{ padding: '10px' }}>
              <Button
                onClick={handleStayMeeting}
                variant="contained"
                style={{ backgroundColor: '#0a58ca', color: 'white', marginRight: '10px' }}
              >
                Stay in the meeting
              </Button>
              <Button
                variant="contained"
                onClick={handleClose}
                autoFocus
                style={{ backgroundColor: '#0a58ca', color: 'white', marginLeft: '10px' }}
              >
                Leave meeting
              </Button>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default MeetingLeaveDialog;
