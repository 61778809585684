import { Callback } from '../../../types';
import { isMobile, getBrowserDetails } from '../../../utils';
import Video, { ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { VideoRoomMonitor } from '@twilio/video-room-monitor';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

// @ts-ignore
window.TwilioVideo = Video;

export function updateRoomParticipantDetails(room: Room) {
  try {
    const browserDetails = getBrowserDetails();

    const OSName = browserDetails?.os?.name || "Unknown OS";
    const browserName = browserDetails?.browser?.name || "Unknown Browser";

    const { localParticipant, name: meeting_id } = room;
    const { sid: participantSid, signalingRegion: joinedRegion } = localParticipant;

    const api_key = window.sessionStorage.getItem('m_key');
    const m_key = api_key ? api_key : "";

    if (m_key && participantSid) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/internal/participant/${participantSid}/attribute`, {
        method: 'PUT',
        body: JSON.stringify({
          device: OSName,
          region: joinedRegion,
          browser: browserName,
          meeting_id: meeting_id
        }),
        headers: {
          'content-type': 'application/json',
          'X-API-KEY': m_key
        },
      });
    }
  } catch (error) {
    console.error("error-participant-update", error)
  }
}

export default function useRoom(localTracks: LocalTrack[], onError: Callback, options?: ConnectOptions) {
  const [room, setRoom] = useState<Room | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const optionsRef = useRef(options);
  const history = useHistory();

  useEffect(() => {
    // This allows the connect function to always access the most recent version of the options object. This allows us to
    // reliably use the connect function at any time.
    optionsRef.current = options;
  }, [options]);

  const connect = useCallback(
    token => {
      setIsConnecting(true);
      return Video.connect(token, { ...optionsRef.current, tracks: localTracks }).then(
        newRoom => {
          setRoom(newRoom);
          // console.log("newRoom",newRoom);
          VideoRoomMonitor.registerVideoRoom(newRoom);
          const disconnect = () => newRoom.disconnect();

          // This app can add up to 16 'participantDisconnected' listeners to the room object, which can trigger
          // a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.
          newRoom.setMaxListeners(16);

          newRoom.once('disconnected', () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => setRoom(null));
            window.removeEventListener('beforeunload', disconnect);

            if (isMobile) {
              window.removeEventListener('pagehide', disconnect);
            }
          });

          // @ts-ignore
          window.twilioRoom = newRoom;

          newRoom.localParticipant.videoTracks.forEach(publication =>
            // All video tracks are published with 'low' priority because the video track
            // that is displayed in the 'MainParticipant' component will have it's priority
            // set to 'high' via track.setPriority()
            publication.setPriority('low')
          );

          updateRoomParticipantDetails(newRoom);
          setIsConnecting(false);

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener('beforeunload', disconnect);

          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener('pagehide', disconnect);
          }

          // try {
          //   const browserDetails = getBrowserDetails();

          //   let OSName = browserDetails?.os?.name || "Unknown OS";
          //   let browserName = browserDetails?.browser?.name || "Unknown Browser";
          //   // let OSName = "Unknown OS";
          //   // // @ts-ignore
          //   // if (navigator.userAgent && navigator.userAgent.indexOf("Win") !== -1) OSName = "Windows";
          //   // // @ts-ignore
          //   // if (navigator.userAgent && navigator.userAgent.indexOf("Mac") !== -1) OSName = "MacOS";
          //   // // @ts-ignore
          //   // if (navigator.userAgent && navigator.userAgent.indexOf("X11") !== -1) OSName = "UNIX";
          //   // // @ts-ignore
          //   // if (navigator.userAgent && navigator.userAgent.indexOf("Linux") !== -1) OSName = "Linux";

          //   const participantSid = newRoom.localParticipant.sid;
          //   const joinedRegion = newRoom.localParticipant.signalingRegion;
          //   const meeting_id = newRoom.name;

          //   const api_key = window.sessionStorage.getItem('m_key');
          //   const m_key = api_key ? api_key : "";
          //   // const m_key = "";

          //   fetch(`${process.env.REACT_APP_API_BASE_URL}/internal/participant/${participantSid}/attribute`, {
          //     method: 'PUT',
          //     body: JSON.stringify({
          //       device: OSName,
          //       region: joinedRegion,
          //       browser: browserName,
          //       meeting_id: meeting_id
          //     }),
          //     headers: {
          //       'content-type': 'application/json',
          //       'X-API-KEY': m_key
          //     },
          //   });
          // } catch (error) {
          //   console.error("error-participant-update", error)
          // }
        },
        error => {
          onError(error);
          setIsConnecting(false);
          history.replace('/timeout');
        }
      );
    },
    [localTracks, onError]
  );

  return { room, isConnecting, connect };
}
