import React, { useState, useRef, useCallback, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Tooltip, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { useAppState } from '../../../state';
// import UserAvatar from './UserAvatar/UserAvatar';
import Menu from '@material-ui/core/Menu';
// import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import Snackbar from '../Snackbar/Snackbar';

import InviteDialog from './InviteDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userContainer: {
      position: 'absolute',
      // bottom: 0,
      right: 0,
      margin: '1em',
      display: 'flex',
      alignItems: 'center',
    },
    userButton: {
      color: 'white',
    },
    inviteLink: {
      cursor: 'pointer',
      padding: '5px 10px',
      marginRight: '20px',
      backgroundColor: '#10416D'
    },
  })
);

const InviteButton: React.FC = () => {
  const classes = useStyles();

  const [inviteOpen, setInviteOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [apiKey, setApiKey] = useState('');

  useEffect(() => {
    const api_key = window.sessionStorage.getItem('m_key');
    const m_key = api_key ? api_key : "";
    setApiKey(m_key);
  }, [])

  const inviteSuccess = () => {
    setInviteOpen(false);
    setIsSuccess(true);
  }

  // if (true) {
  return (
    <>
      <Snackbar
        variant="info"
        headline="Success"
        message="Call invitation sent..."
        open={isSuccess}
        handleClose={() => setIsSuccess(false)}
      />
      {/* <div className={classes.userContainer}> */}
      <Tooltip title="Having trouble with joining meeting? Let us call you." >
        <Button variant="contained" color="primary" onClick={() => setInviteOpen(true)} className={classes.inviteLink}>
          Call Me
        </Button>
      </Tooltip >
      {/* </div> */}
      <InviteDialog
        apiKey={apiKey}
        open={inviteOpen}
        onClose={() => {
          setInviteOpen(false);
        }}
        onSuccess={() => {
          inviteSuccess()
        }}
      />
    </>
  );
  // }
  // return null;
};

export default InviteButton;
