import React from 'react';
const css = String.raw;

export default function VideoOnIcon(props: { className?: string }) {
  return (
    <svg className={props.className} width="24" height="28" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
      <style type="text/css">
        {css`
          .stVideoOnIcon{fill:#FFFFFF;}
        `}
      </style>
      <g>
        <path className="stVideoOnIcon" d="M20.1,6H3.9C2,6,0.5,7.5,0.5,9.4V18c0,4.4,3.6,8,8,8h11.6c1.9,0,3.4-1.5,3.4-3.4V9.4C23.5,7.5,22,6,20.1,6z" />
        <path className="stVideoOnIcon" d="M31.5,24.2c-4.5-0.4-8-4.1-8-8.7c0-4.6,3.5-8.3,8-8.7V24.2z" />
      </g>
    </svg>
  );
}
