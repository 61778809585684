import React from 'react';
const css = String.raw;

export default function MicOffIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
      <style type="text/css">
        {css`
          .stMicOffIcon0{fill:#FFD633;}
          .stMicOffIcon1{fill:#FFFFFF;}
        `}
      </style>
      <g>
        <path className="stMicOffIcon0" d="M16,32L16,32C7.2,32,0,24.8,0,16v0C0,7.2,7.2,0,16,0h0c8.8,0,16,7.2,16,16v0C32,24.8,24.8,32,16,32z" />
        <g>
          <path className="stMicOffIcon1" d="M16,21.5L16,21.5c-2.6,0-4.7-2.1-4.7-4.7v-6.7c0-2.6,2.1-4.7,4.7-4.7h0c2.6,0,4.7,2.1,4.7,4.7v6.7
			C20.7,19.4,18.6,21.5,16,21.5z"/>
          <path className="stMicOffIcon1" d="M16,24.2c-4.1,0-7.4-3.3-7.4-7.4v-1H10v1c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6v-1h1.4v1
			C23.4,20.9,20.1,24.2,16,24.2z"/>
          <rect className="stMicOffIcon1" x="10.3" y="25.2" width="11.4" height="1.4" />
          <rect className="stMicOffIcon1" x="15.3" y="23.8" width="1.4" height="2" />
        </g>
        <rect className="stMicOffIcon1" x="3.8" y="15.3" transform="matrix(0.863 -0.5052 0.5052 0.863 -5.8768 10.2705)" width="24.3" height="1.4" />
        <rect className="stMicOffIcon0" x="3.8" y="13.7" transform="matrix(0.863 -0.5052 0.5052 0.863 -5.0875 10.0564)" width="24.3" height="1.4" />
      </g>
    </svg>
  );
}
