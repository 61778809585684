import React from 'react';
const css = String.raw;


export default function ScreenShareIcon() {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    //   <path
    //     fill="#707578"
    //     fillRule="evenodd"
    //     d="M17.657 6.343c3.124 3.124 3.124 8.19 0 11.314-3.124 3.124-8.19 3.124-11.314 0-3.124-3.124-3.124-8.19 0-11.314 3.124-3.124 8.19-3.124 11.314 0zm-10.444.87c-2.643 2.644-2.643 6.93 0 9.574 2.644 2.643 6.93 2.643 9.574 0 2.57-2.57 2.641-6.693.214-9.35l-.214-.224L16.563 7c-2.657-2.427-6.78-2.356-9.35.214zm4.865.484l.02.003.026.005.041.01c.025.007.045.014.063.021l.043.019c.018.009.036.019.053.03.01.005.02.012.03.02.027.019.053.04.077.063l2.773 2.774c.24.24.24.63 0 .87-.213.214-.545.237-.785.071l-.085-.07-1.719-1.72v5.9c0 .34-.275.615-.615.615-.302 0-.553-.218-.605-.505l-.01-.11-.001-5.9-1.718 1.72c-.24.24-.63.24-.87 0-.214-.215-.238-.546-.072-.786l.072-.085 2.769-2.77.015-.014c.02-.018.04-.035.062-.05l.005-.004.008-.006c.027-.018.055-.034.085-.048l.034-.015.059-.02.032-.008c.021-.005.044-.008.068-.011l.038-.003H12.025l.027.002.026.002z"
    //   />
    // </svg>

    <svg width="32" height="32"  viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
      <style type="text/css">
        {css`
          .stScreenShareIcon{fill:#FFFFFF;}
        `}
      </style>
      <rect x="9.3" y="27.2" className="stScreenShareIcon" width="13.4" height="1" />
      <g>
        <rect x="11.6" y="24" className="stScreenShareIcon" width="1" height="3.7" />
        <rect x="19.4" y="24" className="stScreenShareIcon" width="1" height="3.7" />
      </g>
      <path className="stScreenShareIcon" d="M27.7,3.8H4.3c-1.1,0-2,0.9-2,2v17c0,1.1,0.9,2,2,2h23.3c1.1,0,2-0.9,2-2v-17C29.7,4.8,28.8,3.8,27.7,3.8z
M19.8,15.2l-3.3-3.3v7.4h-1v-7.4l-3.3,3.3l-0.7-0.7L16,9.9l4.5,4.5L19.8,15.2z"/>
    </svg>
  );
}
