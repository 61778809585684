import React from 'react';
const css = String.raw;

export default function EndCallIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
      <style type="text/css">
        {css`
          .stEndCallIcon0{fill:#FF0000;}
          .stEndCallIcon1{fill:#FFFFFF;}
        `}
      </style>
      <g>
        <circle className="stEndCallIcon0" cx="16" cy="16" r="16" />
        <g>
          <path className="stEndCallIcon1" d="M6.8,22.3c-0.5,0-1-0.3-1.2-0.7c-1-1.7-2.5-4.8-1.1-7.2c0.9-1.5,6.3-3.3,11.3-3.3c0,0,0,0,0,0
			c0.8,0,1.6,0,2.4,0.1l-0.1,0.9C17.3,12,16.5,12,15.8,12c0,0,0,0,0,0c-5.2,0-9.9,1.9-10.5,2.9c-1.2,2,0.4,5,1.1,6.2
			c0.1,0.2,0.4,0.3,0.6,0.2l2.6-1c0.2-0.1,0.3-0.2,0.3-0.3c0.1-0.2,0-0.3-0.1-0.5c-0.5-0.9-0.7-1.7-0.5-2.3c0.2-0.6,0.7-1.1,1.5-1.4
			c2.3-0.9,4.3-1.1,5-1.2l0.1,0.9c-0.7,0-2.5,0.2-4.7,1.1c-0.5,0.2-0.8,0.5-0.9,0.8c-0.2,0.5,0.1,1.1,0.4,1.6
			c0.2,0.4,0.3,0.8,0.1,1.2s-0.5,0.7-0.9,0.9l-2.6,1C7.2,22.2,7,22.3,6.8,22.3z"/>
          <path className="stEndCallIcon1" d="M25,22.3c-0.2,0-0.3,0-0.5-0.1l-2.6-1c-0.4-0.2-0.7-0.5-0.9-0.9c-0.1-0.4-0.1-0.9,0.1-1.2
			c0.3-0.4,0.6-1.1,0.4-1.6c-0.1-0.3-0.4-0.6-0.9-0.8c-1-0.4-2-0.7-3-0.9l0.2-0.9c1.1,0.2,2.1,0.5,3.2,0.9c0.8,0.3,1.3,0.8,1.5,1.4
			c0.2,0.7,0.1,1.5-0.5,2.3c-0.1,0.1-0.1,0.3-0.1,0.5c0.1,0.2,0.2,0.3,0.3,0.3l2.6,1c0.2,0.1,0.5,0,0.6-0.2c0.7-1.2,2.3-4.2,1.1-6.2
			c-0.4-0.6-2.7-1.8-6.3-2.5l0.2-0.9c2.9,0.5,6.2,1.7,6.9,2.9c1.4,2.3-0.1,5.5-1.1,7.2C26,22,25.5,22.3,25,22.3z"/>
          <rect className="stEndCallIcon1" x="7.5" y="15.2" transform="matrix(0.6386 -0.7695 0.7695 0.6386 -5.8563 18.9043)" width="19.5" height="0.9" />
        </g>
      </g>
    </svg>
  );
}
