import React, { SVGProps } from 'react';
const css = String.raw;

export default function DialecticaLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 254 61.9" fill="#10416D" xmlSpace="preserve" {...props}>
      <style type="text/css">
        {css`
          .st0 {
            fill: #10416D;
          }
        `}
      </style>
      <g id="Group_745" transform="translate(-977 781)">
        <path id="Path_1029" className="st0" d="M1058.6-753.5c0,1.5-0.3,3-0.8,4.4c-0.5,1.3-1.3,2.5-2.3,3.4c-1,1-2.2,1.7-3.5,2.2
		c-1.5,0.5-3,0.8-4.6,0.8h-8.5v-21.7h8.5c1.6,0,3.1,0.2,4.6,0.8c1.3,0.5,2.5,1.2,3.5,2.2c1,1,1.8,2.1,2.3,3.4
		C1058.4-756.5,1058.7-755,1058.6-753.5z M1055.2-753.5c0-1.2-0.2-2.4-0.5-3.5c-0.3-1-0.9-1.9-1.6-2.6c-0.7-0.7-1.5-1.3-2.4-1.6
		c-1-0.4-2.1-0.6-3.2-0.6h-5.1v16.5h5.1c1.1,0,2.2-0.2,3.2-0.6c0.9-0.4,1.8-0.9,2.5-1.6c0.7-0.7,1.2-1.6,1.6-2.6
		C1055-751.1,1055.2-752.3,1055.2-753.5L1055.2-753.5z M1067.4-742.6h-3.4v-21.7h3.4V-742.6z M1086.2-750.7l-3.3-8.4
		c-0.3-0.7-0.5-1.4-0.7-2.2c-0.1,0.4-0.2,0.8-0.4,1.2c-0.1,0.4-0.2,0.7-0.4,1l-3.3,8.4H1086.2z M1092.6-742.6h-2.4
		c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.3-0.3-0.4-0.5l-2-5h-9.9l-2,5c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.2-0.5,0.2-0.7,0.2h-2.6l9-21.7
		h3.4L1092.6-742.6z M1110-745.3v2.7h-12.9v-21.7h3.3v19H1110z M1128.3-745.2l0,2.6h-14v-21.7h14v2.6h-10.6v6.9h8.5v2.5h-8.5v7.1
		L1128.3-745.2z M1148.7-747.3c0.2,0,0.4,0.1,0.5,0.2l1.3,1.4c-0.9,1.1-2.1,1.9-3.4,2.4c-1.5,0.6-3.1,0.9-4.8,0.9
		c-1.5,0-3-0.3-4.4-0.8c-1.3-0.5-2.4-1.3-3.4-2.3c-1-1-1.7-2.2-2.2-3.5c-0.5-1.4-0.8-3-0.8-4.5c0-1.5,0.3-3.1,0.8-4.5
		c1-2.7,3.1-4.8,5.8-5.8c1.4-0.6,3-0.8,4.5-0.8c1.5,0,2.9,0.2,4.3,0.8c1.2,0.5,2.3,1.2,3.2,2l-1.1,1.5c-0.1,0.1-0.2,0.2-0.3,0.3
		c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.5-0.1-0.7-0.3c-0.3-0.2-0.7-0.4-1-0.6c-1.2-0.7-2.6-1-4-0.9c-1.1,0-2.1,0.2-3.1,0.6
		c-0.9,0.4-1.7,0.9-2.4,1.6c-0.7,0.8-1.2,1.6-1.6,2.6c-0.4,1.1-0.6,2.3-0.6,3.5c0,1.2,0.2,2.4,0.6,3.5c0.4,1,0.9,1.9,1.6,2.6
		c0.7,0.7,1.5,1.3,2.4,1.6c0.9,0.4,2,0.6,3,0.5c0.6,0,1.2,0,1.7-0.1c0.5-0.1,1-0.2,1.4-0.3c0.4-0.1,0.8-0.3,1.2-0.6
		c0.4-0.3,0.8-0.5,1.1-0.9c0.1-0.1,0.2-0.1,0.3-0.2C1148.5-747.3,1148.6-747.3,1148.7-747.3z M1170.5-761.7h-7.2v19h-3.3v-19h-7.2
		v-2.7h17.7V-761.7z M1178.5-742.6h-3.4v-21.7h3.4L1178.5-742.6z M1200.9-747.3c0.2,0,0.4,0.1,0.5,0.2l1.3,1.4
		c-0.9,1.1-2.1,1.9-3.4,2.4c-1.5,0.6-3.1,0.9-4.8,0.9c-1.5,0-3-0.3-4.4-0.8c-1.3-0.5-2.4-1.3-3.4-2.3c-1-1-1.7-2.2-2.2-3.5
		c-0.5-1.4-0.8-3-0.8-4.5c0-1.5,0.3-3.1,0.8-4.5c1-2.7,3.1-4.8,5.8-5.8c1.4-0.6,3-0.8,4.5-0.8c1.5,0,2.9,0.2,4.3,0.8
		c1.2,0.5,2.3,1.2,3.2,2l-1.1,1.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.5-0.1-0.7-0.3
		c-0.3-0.2-0.7-0.4-1-0.6c-1.2-0.7-2.6-1-4-0.9c-1.1,0-2.1,0.2-3.1,0.6c-0.9,0.4-1.7,0.9-2.4,1.6c-0.7,0.8-1.2,1.6-1.6,2.6
		c-0.4,1.1-0.6,2.3-0.6,3.5c0,1.2,0.2,2.4,0.6,3.5c0.4,1,0.9,1.9,1.6,2.6c0.7,0.7,1.5,1.3,2.4,1.6c0.9,0.4,2,0.6,3,0.6
		c0.6,0,1.2,0,1.7-0.1c0.5-0.1,1-0.2,1.4-0.3c0.4-0.1,0.8-0.3,1.2-0.6c0.4-0.3,0.8-0.5,1.1-0.9c0.1-0.1,0.2-0.1,0.3-0.2
		C1200.7-747.3,1200.8-747.3,1200.9-747.3L1200.9-747.3z M1219.5-750.7l-3.3-8.4c-0.3-0.7-0.5-1.4-0.7-2.2c-0.1,0.4-0.2,0.8-0.4,1.2
		c-0.1,0.4-0.2,0.7-0.4,1l-3.3,8.4L1219.5-750.7z M1226.1-742.6h-2.6c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.3-0.3-0.4-0.5l-2-5h-9.9
		l-2,5c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.2-0.5,0.2-0.7,0.2h-2.6l9-21.7h3.4L1226.1-742.6z"/>
        <g id="Group_620" transform="translate(976.999 -780.999)">
          <path id="Path_869" className="st0" d="M23.9,47.1C12.1,46.2,3.2,35.9,4.1,24c0.5-6.8,4.2-13,10.1-16.7l0.4-0.3l-1.8-3.6
			C0.5,10.6-3.6,26.2,3.4,38.4c4.2,7.3,11.8,12,20.2,12.7c3.6,0.2,7.2-0.2,10.7-1.1L33.3,46C30.2,46.8,27.1,47.1,23.9,47.1
			L23.9,47.1z"/>
          <path id="Path_870" className="st0" d="M51.1,27.2c1-14-9.5-26.1-23.4-27.1c-3.6-0.3-7.2,0.2-10.6,1.5l1.4,3.7l0,0
			c11.1-4.1,23.3,1.6,27.4,12.6c1.1,2.9,1.5,5.9,1.3,9c-0.6,5.9-3.1,13.8-10.5,17.2l1.5,3.2l1.3,2.6l2.5,5.1
			c0.2-0.1,0.3-9.8,0.5-9.9C47.6,41.2,50.5,34,51.1,27.2z"/>
        </g>
        <g id="Group_621" transform="translate(1018.454 -737.795)">
          <path id="Path_871" className="st0" d="M-27.2-30c0.4,1.3,3.6,1.4,7.1,0.2c3.5-1.2,6-3.1,5.6-4.4c-0.1-0.4-0.6-0.7-1.3-0.9
			c-3.9,0-7.7,1.2-10.8,3.6C-27.1-31.2-27.3-30.6-27.2-30z"/>
          <path id="Path_872" className="st0" d="M-15.5-15.4c-8,2.4-15.3,2.4-18.2,0.2c0.1,0.8,0.2,1.7,0.4,2.5c0.3,1.3,0.7,2.5,1.2,3.7
			C-32-9-32-9-32-9c5.3,1.8,11,1.4,18.4-0.8C-6.4-12-0.3-16.2,2-19.8c-0.1-0.6-0.2-1.2-0.4-1.8c-0.4-1.5-1-3-1.7-4.3
			C-1-22.3-7.2-17.9-15.5-15.4z"/>
          <path id="Path_873" className="st0" d="M-12.3-4.4C-17-2.9-22.1-2.4-27-3c1.5,1.2,3.2,2.1,5,2.8c2.4,0.7,6.4,0.5,10.8-0.8
			s7.9-3.2,9.6-5c1.4-1.8,2.4-3.8,3.1-6C-2.5-8.4-7.2-5.8-12.3-4.4L-12.3-4.4z"/>
          <path id="Path_874" className="st0" d="M-17.2-21.4c7-2.1,12.3-5.7,13.8-8.8c-2-1.9-4.4-3.3-7-4.1c0.1,0.2,0.2,0.3,0.3,0.5
			c0.7,2.1-3.5,5.4-9.3,7.4c-5.7,1.9-10.9,1.8-11.8-0.2c-1.1,1.7-1.8,3.6-2.3,5.6C-30.5-19.2-24.1-19.3-17.2-21.4z"/>
        </g>
      </g>
    </svg>
  );
}
