import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// import CryptoJS from 'crypto-js';
import { getDecryptedPWD } from '../../utils';

const DECODE_KEY = "1!2kapTea@3";

export function getPasscode() {
  const searchParams = new URLSearchParams(window.location.search);
  // const match = window.location.search.match(/pwd=(.*)&?/);
  // const pwd = match ? match[1] : ""
  const pwd = searchParams.get('pwd') || "";
  // const decryptedData = pwd ? CryptoJS.AES.decrypt(pwd, `${DECODE_KEY}`).toString(CryptoJS.enc.Utf8) : "";
  const decryptedData = getDecryptedPWD(pwd.replace(/ /gi, "+"));
  // console.log("decryptedData--", decryptedData);
  const decryptedPwd = decryptedData ? JSON.parse(decryptedData).passcode : "";
  const passcode = decryptedPwd ? decryptedPwd : window.sessionStorage.getItem('passcode');
  // console.log("passcode--", passcode);
  // const passcode = decData ? decData.toString(CryptoJS.enc.Utf8) : window.sessionStorage.getItem('passcode');

  return passcode;
}

export function getJoinToken() {
  const searchParams = new URLSearchParams(window.location.search);
  const jtoken = searchParams.get('join_token') || "";

  let join_token = jtoken ? jtoken.replace(/ /gi, "+") : window.sessionStorage.getItem('jtoken');

  return join_token;
}

export function getMeetingId() {
  const match = window.location.href.match(/meeting\/(.*)\?/) || "";
  const meetingId = match ? match[1] : window.sessionStorage.getItem('meetingId');
  return meetingId;
}

export function getChaperoningStatus() {
  // const match = window.location.search.match(/pwd=(.*)&?/);
  // const pwd = match ? match[1] : ""
  const searchParams = new URLSearchParams(window.location.search);
  const pwd = searchParams.get('pwd') || "";
  let chaperoningMode;

  if (pwd) {
    const decryptedData = getDecryptedPWD(pwd.replace(/ /gi, "+"));
    // console.log("decryptedData--", decryptedData);
    chaperoningMode = decryptedData ? JSON.parse(decryptedData).chaperoning_mode ? JSON.parse(decryptedData).chaperoning_mode : false : false;
    window.sessionStorage.setItem('cpMode', chaperoningMode);
  } else {
    const cpMode = window.sessionStorage.getItem('cpMode');
    chaperoningMode = cpMode == "true" ? true : false;
  }

  return chaperoningMode;
}

export async function fetchMeeting(meetingId: string) {
  const api_key = window.sessionStorage.getItem('m_key');
  const m_key = api_key ? api_key : "";

  return fetch(`${process.env.REACT_APP_API_BASE_URL}/api/meeting/${meetingId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'X-API-KEY': m_key
    },
  });
}

export function getMeetingData(meetingId: string) {
  return fetchMeeting(meetingId).then(
    async res => {
      const jsonResponse = await res.json();
      // console.log("MEETING-Res--", jsonResponse);
      if (res.status === 401) {
        return { isValid: false, error: jsonResponse.error?.message, passcode: "", meeting: null };
      }

      if (res.ok && jsonResponse && jsonResponse.passcode) {
        // if (jsonResponse.is_archived || jsonResponse.is_deleted) {
        //   return { isValid: false, error: "Unable to connect meeting", passcode: "", meeting: null };
        // } else {
          return { isValid: true, passcode: jsonResponse.passcode, meeting: jsonResponse };
        // }
      } else {
        return { isValid: false, error: "Unable to connect meeting", passcode: "", meeting: null };
      }
    }
  );
}

export function fetchToken(
  name: string,
  room: string,
  passcode: string,
  create_room = true,
  create_conversation = process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true'
) {

  // console.log(name, room, passcode, create_room, create_conversation);
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/token-verify`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      user_identity: name,
      room_name: room,
      passcode,
      create_room,
      create_conversation
    }),
  });
}

export function verifyPasscode(meetingId: string, passcode: string) {
  return fetchToken('temp-name', meetingId, passcode, false /* create_room */, false /* create_conversation */).then(
    async res => {
      const jsonResponse = await res.json();
      if (res.status !== 200) {
        return { isValid: false, error: jsonResponse.error?.message, explanation: jsonResponse.error?.explanation };
      }

      if (res.ok && jsonResponse.token) {
        window.sessionStorage.setItem('m_key', jsonResponse.meeting_key);
        return { isValid: true };
      }
    }
  );
}

export function getErrorMessage(message: string) {
  switch (message) {
    case 'passcode incorrect':
      return 'Passcode is incorrect';
    case 'passcode expired':
      return 'Passcode has expired';
    default:
      return message;
  }
}

export default function usePasscodeAuth() {
  const history = useHistory();

  const [user, setUser] = useState<{ displayName: undefined; photoURL: undefined; passcode: string; } | null>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const [meeting, setMeeting] = useState(null);
  const [meetingError, setMeetingError] = useState<Error | null>(null);

  const getToken = useCallback(
    (name: string, room: string) => {
      return fetchToken(name, room, user!.passcode)
        .then(async res => {
          if (res.ok) {
            const responseJson = await res.json();
            window.sessionStorage.setItem('m_key', responseJson.meeting_key);
            // console.log("responseJson", responseJson)
            return responseJson;
          }
          const json = await res.json();
          const errorMessage = getErrorMessage(json.error?.message || res.statusText);
          throw Error(errorMessage);
        })
        .then(res => res);
    },
    [user]
  );

  const updateRecordingRules = useCallback(
    async (room_sid, rules) => {
      const api_key = window.sessionStorage.getItem('m_key');
      const m_key = api_key ? api_key : "";

      return fetch(`${process.env.REACT_APP_API_BASE_URL}/recordingrules`, {
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': m_key
        },
        body: JSON.stringify({ room_sid, rules, passcode: user?.passcode }),
        method: 'POST',
      }).then(async res => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(jsonResponse.error?.message || 'There was an error updating recording rules');
          error.code = jsonResponse.error?.code;

          return Promise.reject(error);
        }

        return jsonResponse;
      });
    },
    [user]
  );

  useEffect(() => {
    const meetingId = getMeetingId();
    const passcode = getPasscode();
    const chaperoningStatus = getChaperoningStatus();
    // const join_token = getJoinToken();

    if (meetingId && passcode) {
      verifyPasscode(meetingId, passcode)
        .then(verification => {
          if (verification?.isValid) {

            getMeetingData(meetingId)
              .then(meetingData => {
                // console.log("meetingData-verify", meetingData);
                if (meetingData && meetingData?.isValid && meetingData.meeting) {
                  const meetingDetails = { ...meetingData.meeting, isChaperoning: chaperoningStatus }
                  setMeeting(meetingDetails);

                  setUser({ passcode: passcode } as any);
                  window.sessionStorage.setItem('passcode', passcode);
                  window.sessionStorage.setItem('meetingId', meetingId);
                  history.replace(`/meeting/${meetingId}`);
                  // history.replace(window.location.pathname);
                } else {
                  setMeetingError(new Error(meetingData?.error))
                }
              })
          } else {
            setMeetingError(new Error(verification?.explanation || verification?.error))
          }
        })
        .then(() => setIsAuthReady(true));
    } else {
      setIsAuthReady(true);
    }
  }, [history]);

  const signIn = useCallback((meetingId: string, passcode: string) => {
    setMeetingError(null);
    return verifyPasscode(meetingId, passcode).then(async (verification) => {
      if (verification?.isValid) {

        const meetingData = await getMeetingData(meetingId)

        if (meetingData?.isValid) {
          setMeeting(meetingData?.meeting);

          setUser({ passcode } as any);
          window.sessionStorage.setItem('passcode', passcode);
          window.sessionStorage.setItem('meetingId', meetingId);
        } else {
          throw new Error(getErrorMessage(meetingData?.error));
        }
      } else {
        throw new Error(getErrorMessage(verification?.error));
      }
    });
  }, []);

  const signOut = useCallback(() => {
    setUser(null);
    window.sessionStorage.removeItem('passcode');
    window.sessionStorage.removeItem('meetingId');
    window.sessionStorage.removeItem('m_key');
    window.sessionStorage.removeItem('cpMode');
    window.sessionStorage.removeItem('jtoken');
    return Promise.resolve();
  }, []);

  return { user, isAuthReady, getToken, signIn, signOut, updateRecordingRules, meeting, meetingError };
}
