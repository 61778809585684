import React from 'react';

import List from '@material-ui/core/List';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../../VideoProvider/useSelectedParticipant/useSelectedParticipant';

import Participant from '../Participant/Participant';

export default function ParticipantList({ participants }: any) {
  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;

  const [selectedParticipant] = useSelectedParticipant();

  // console.log("room",room);
  // console.log("room-localParticipant",room?.localParticipant);
  // const localParticipant = room!.localParticipant;
  // console.log("localParticipant",localParticipant);
  // console.log("participants---->>", participants);

  return (
    <List>
      {/* <Participant
        key={localParticipant.sid}
        participant={localParticipant}
        isLocalParticipant={true}
      /> */}
      {participants.map((participant: any) => {
        return (
          <Participant
            key={participant.sid}
            participant={participant}
            isLocalParticipant={participant === localParticipant}
            isSelected={participant === selectedParticipant}
          />
        );
      })}
    </List>
  );
}
