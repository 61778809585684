import React from 'react';
const css = String.raw;

export default function ParticipantIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
      <style type="text/css">
        {css`
          .stParticipantIcon{fill:#FFFFFF;}
        `}
      </style>
      <g>
        <circle className="stParticipantIcon" cx="10.4" cy="9.7" r="5.1" />
        <path className="stParticipantIcon" d="M19.7,24.2c0,0-0.7-4.3-2.7-5.7c-2-1.5-4.5-2.2-4.5-2.2h-1.8H10H8.2c0,0-2.5,0.7-4.5,2.2
		c-2,1.5-2.7,5.7-2.7,5.7s-1.2,3,4.2,3.1c2.9,0.1,4.4,0.1,5.2,0.1c0.7,0,2.2,0,5.2-0.1C20.9,27.1,19.7,24.2,19.7,24.2z"/>
        <circle className="stParticipantIcon" cx="23.6" cy="13" r="4" />
        <path className="stParticipantIcon" d="M31,24.6c0,0-0.5-3.4-2.2-4.6c-1.6-1.2-3.6-1.8-3.6-1.8h-1.5h-0.5h-1.5c0,0-1.2,0.4-2.5,1.1
		c0.7,1.3,1.8,3,2,4.9c0.2,1.9-1,2.9-1.2,2.9c1.9,0.1,2.9,0.1,3.4,0.1c0.6,0,1.8,0,4.1-0.1C32,26.9,31,24.6,31,24.6z"/>
      </g>
    </svg>

  );
}
