import React from 'react';
const css = String.raw;

export default function VideoOffSmallIcon() {
  return (
    <svg height="24" width="28" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
      <style type="text/css">
        {css`
          .stVideoOffIcon0{fill:#FF0000;}
          .stVideoOffIcon1{fill:#FFFFFF;}
        `}
      </style>
      <g>
        <path className="stVideoOffIcon0" d="M16,32L16,32C7.2,32,0,24.8,0,16v0C0,7.2,7.2,0,16,0h0c8.8,0,16,7.2,16,16v0C32,24.8,24.8,32,16,32z" />
        <g>
          <path className="stVideoOffIcon1" d="M18.3,24.2h-7.5c-3.2,0-5.8-2.6-5.8-5.8v-5.5c0-1.6,1.3-2.9,2.9-2.9h10.4c1.6,0,2.9,1.3,2.9,2.9v8.5
			C21.2,22.9,19.9,24.2,18.3,24.2z M7.9,11.3c-0.8,0-1.5,0.7-1.5,1.5v5.5c0,2.4,2,4.4,4.4,4.4h7.5c0.8,0,1.5-0.7,1.5-1.5v-8.5
			c0-0.8-0.7-1.5-1.5-1.5H7.9z"/>
          <path className="stVideoOffIcon1" d="M26.4,24.9l-0.8-0.1c-3.3-0.4-5.8-3.8-5.8-8.1c0-4.2,2.5-7.7,5.8-8.1l0.8-0.1V24.9z M25,10.2
			c-2.2,0.7-3.7,3.4-3.7,6.5c0,3.1,1.6,5.8,3.7,6.5V10.2z"/>
        </g>
        <rect className="stVideoOffIcon1" x="3.7" y="17.6" transform="matrix(0.8628 -0.5056 0.5056 0.8628 -6.9822 10.9537)" width="26" height="1.4" />
        <rect className="stVideoOffIcon0" x="3.6" y="16.2" transform="matrix(0.8619 -0.5071 0.5071 0.8619 -6.2785 10.6616)" width="25.7" height="1.4" />
      </g>
    </svg>
  );
}
