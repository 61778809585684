import { useContext } from 'react';
import { VoiceContext } from '../../components/VoiceProvider';

export default function useVoiceContext() {
  const context = useContext(VoiceContext);
  if (!context) {
    throw new Error('useVoiceContext must be used within a VoiceProvider');
  }
  return context;
}
