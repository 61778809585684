import React from 'react';
// import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import AudioLevelIndicator from '../../AudioLevelIndicator/AudioLevelIndicator';
// import NetworkQualityLevel from '../NetworkQualityLevel/NetworkQualityLevel';
import PinIcon from '../PinIcon/PinIcon';
import ScreenShareIcon from '../../../icons/ScreenShareIcon';
import VideoOffSmallIcon from '../../../icons/VideoOffSmallIcon';
import VideoOnSmallIcon from '../../../icons/VideoOnSmallIcon';

import useIsTrackSwitchedOff from '../../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import usePublications from '../../../hooks/usePublications/usePublications';
import useTrack from '../../../hooks/useTrack/useTrack';
import useParticipantIsReconnecting from '../../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';

import { getIdentityInitials, removeIdentityMasking } from '../../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '204px',
      marginRight: '8px',
      marginBottom: '0',
      paddingTop: `calc(${theme.sidebarHeight}px - ${theme.participantBorderWidth}px)`,
      // marginBottom: '0.5em',
      // paddingTop: `calc(${(9 / 16) * 100}% - ${theme.participantBorderWidth}px)`,
      height: 0,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      '& video': {
        filter: 'none',
        objectFit: 'contain !important',
      },
      borderRadius: '10px',
      // border: `${theme.participantBorderWidth}px solid rgb(245, 248, 255)`,
      border: `${theme.participantBorderWidth}px solid rgb(119, 119, 119)`,
      background: 'black',
      [theme.breakpoints.down('sm')]: {
        height: theme.sidebarMobileHeight,
        width: `${(theme.sidebarMobileHeight * 16) / 9}px`,
        marginRight: '8px',
        marginBottom: '0',
        fontSize: '12px',
        paddingTop: `${theme.sidebarMobileHeight - theme.participantBorderWidth}px`,
      },
    },
    innerContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      width: '100%',
      background: 'transparent',
      top: 0,
    },
    avatarContainer: {
      width: '45px',
      height: '45px',
      borderRadius: '12px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#191b1d',
      color: 'white',
      fontSize: 'medium',
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
        '& svg': {
          transform: 'scale(0.7)',
        },
      },
    },
    reconnectingContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'center',
      background: 'rgba(40, 42, 43, 0.75)',
      zIndex: 1,
    },
    screenShareIconContainer: {
      // background: 'rgba(0, 0, 0, 0.5)',
      background: 'white',
      // padding: '0.18em 0.3em',
      // marginRight: '0.3em',
      width: '22px',
      height: '28px',
      display: 'flex',
      '& path': {
        // fill: 'white',
        fill: '#10416D',
      },
      '& rect': {
        fill: '#10416D',
      }
    },
    identity: {
      background: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      padding: '0.18em 0.3em',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      borderRadius: '10px'
    },
    identityType: {
      background: '#FFA85C',
      color: 'white',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      borderRadius: '10px',
      justifyContent: 'center',
      minWidth: '36px',
      maxHeight: '19px',
      fontSize: '0.7rem'
    },
    infoRowBottom: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      bottom: 0,
      left: 0,
      alignItems: 'center'
    },
    typeography: {
      color: '#10416D',
      fontSize: '0.7rem',
      [theme.breakpoints.down('sm')]: {
        // fontSize: '0.75rem',
      },
      maxWidth: '50%',

    },
    typeographySecondary: {
      color: '#10416D',
      fontSize: '1rem',
    },
    hideParticipant: {
      display: 'none',
    },
    cursorPointer: {
      cursor: 'pointer',
    },
    actionContainer: {
      display: 'flex',
      maxHeight: '28px',
      overflow: 'hidden',
      alignItems: 'center'
    },
    videoIconContainer: {
      height: '28px',
      '& .videoOn *': {
        fill: '#10416D'
      }
    },
  })
);

// const getIdentityInitials = (identity: string) => {
//   const title = identity.split(' ').map((x: string) => { return x[0] }).join('').slice(0, 2).toUpperCase();
//   return title;
// }

// const removeMasking = (identity: string) => {
//   let title = "";
//   if (identity.startsWith('+')) {
//     title = identity.split('-')[0];
//   } else {
//     title = identity.split('###')[0];
//   }
//   return title;
// }

interface ParticipantInfoProps {
  participant: Participant;
  onClick?: () => void;
  isLocalParticipant?: boolean;
  isSelected?: boolean;
}

export default function ParticipantInfo({
  participant,
  onClick,
  isLocalParticipant,
  isSelected
}: ParticipantInfoProps) {
  const publications = usePublications(participant);

  const audioPublication = publications.find(p => p.kind === 'audio');
  const videoPublication = publications.find(p => !p.trackName.includes('screen') && p.kind === 'video');

  const isVideoEnabled = Boolean(videoPublication);
  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);

  const isScreenShareEnabled = publications.find(p => p.trackName.includes('screen'));

  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const classes = useStyles();

  return (
    <ListItem >
      <ListItemAvatar>
        <span className={classes.avatarContainer}>
          {getIdentityInitials(participant.identity)}
        </span>
      </ListItemAvatar>
      <ListItemText
        primary={<Typography variant="body1" className={classes.typeography} component="span">{removeIdentityMasking(participant.identity)}</Typography>}
        secondary={isParticipantReconnecting ? (
          <div className={classes.reconnectingContainer}>
            <Typography variant="body1" className={classes.typeographySecondary}>
              Reconnecting...
            </Typography>
          </div>
        ) : ""}
      />
      <ListItemSecondaryAction className={classes.actionContainer} >
        {isLocalParticipant && <span className={classes.identityType}> ME </span>}
        <AudioLevelIndicator audioTrack={audioTrack} color="#10416D" />
        <span className={classes.videoIconContainer}>
          {(!isVideoEnabled || isVideoSwitchedOff) ? <VideoOffSmallIcon /> : <VideoOnSmallIcon className="videoOn" />}
        </span>
        {/* <NetworkQualityLevel participant={participant} /> */}
        {isSelected && <PinIcon />}
        {isScreenShareEnabled && (
          <span className={classes.screenShareIconContainer}>
            <ScreenShareIcon />
          </span>
        )}
      </ListItemSecondaryAction >
    </ListItem>
    // <div
    //   className={clsx(classes.container, {
    //     [classes.hideParticipant]: hideParticipant,
    //     [classes.cursorPointer]: Boolean(onClick),
    //   })}
    //   onClick={onClick}
    //   data-cy-participant={participant.identity}
    // >
    //   <div className={classes.infoContainer}>
    //     <NetworkQualityLevel participant={participant} />
    //     <div className={classes.infoRowBottom}>
    //       {isScreenShareEnabled && (
    //         <span className={classes.screenShareIconContainer}>
    //           <ScreenShareIcon />
    //         </span>
    //       )}
    //       <span className={classes.identity}>
    //         <AudioLevelIndicator audioTrack={audioTrack} />
    //         <Typography variant="body1" className={classes.typeography} component="span">
    //           {/* {participant.identity} */}
    //           {removeMasking(participant.identity)}
    //           {/* {isLocalParticipant && ' (You)'} */}
    //         </Typography>
    //       </span>
    //       <span className={classes.identityType}>
    //         <Typography variant="body1" className={classes.typeography} component="span">
    //           {isLocalParticipant && 'ME'}
    //         </Typography>
    //       </span>
    //     </div>
    //     <div style={{ position: 'absolute', bottom: '0px', right: '0px' }}>
    //       {isSelected && <PinIcon />}
    //     </div>
    //   </div>
    //   <div className={classes.innerContainer}>
    //     {(!isVideoEnabled || isVideoSwitchedOff) && (
    //       <div className={classes.avatarContainer}>
    //         {/* <AvatarIcon /> */}
    //         {getIdentityInitials(participant.identity)}
    //       </div>
    //     )}
    //     {isParticipantReconnecting && (
    //       <div className={classes.reconnectingContainer}>
    //         <Typography variant="body1" className={classes.typeography}>
    //           Reconnecting...
    //         </Typography>
    //       </div>
    //     )}
    //   </div>
    // </div>
  );
}
