import React, { PropsWithChildren, ChangeEvent, useState, FormEvent, useEffect, useCallback } from 'react';
import { useAppState } from '../../state';

import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
// import { ReactComponent as GoogleLogo } from './google-logo.svg';
import { InputLabel, Theme } from '@material-ui/core';
import IntroContainer from '../IntroContainer/IntroContainer';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';


import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory, useParams } from 'react-router-dom';

const phoneNumberRegex = new RegExp(/^\+[0-9]+$/);

const useStyles = makeStyles((theme: Theme) => ({
  googleButton: {
    background: 'white',
    color: 'rgb(0, 94, 166)',
    borderRadius: '4px',
    border: '2px solid rgb(2, 122, 197)',
    margin: '1.8em 0 0.7em',
    textTransform: 'none',
    boxShadow: 'none',
    padding: '0.3em 1em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:hover': {
      background: 'white',
      boxShadow: 'none',
    },
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
  gutterBottom: {
    marginBottom: '1em',
  },
  passcodeContainer: {
    minHeight: '70px',
    // marginRight: '5px'
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    justifyContent: 'center'
  },
}));

interface InviteFormProps {
  apiKey: string
  onSuccess(): void;
  topic?: string;
}

export default function InviteForm({ onSuccess, apiKey, topic = "" }: PropsWithChildren<InviteFormProps>) {
  const classes = useStyles();

  let { meetingId } = useParams<{ meetingId?: string }>();

  // const history = useHistory();
  // const location = useLocation<{ from: Location }>();
  const [countryCode, setCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');


  const [error, setError] = useState('');
  const [inProgress, setInProgress] = useState(false);

  const sendInvite = useCallback((contactNumber: string) => {

    // const api_key = window.sessionStorage.getItem('m_key');
    // const m_key = api_key ? api_key : "";

    return fetch(`${process.env.REACT_APP_API_BASE_URL}/internal/invite/number`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-API-KEY': apiKey
      },
      body: JSON.stringify({
        phoneNumber: contactNumber,
        meeting_id: meetingId
      }),
    }).then(async res => {
      const jsonResponse = await res.json();
      // console.log("jsonResponse", jsonResponse);

      if (!res.ok) {
        const error = new Error(jsonResponse.error?.message || 'There was an error while sending invitation.');
        error.code = jsonResponse.error?.code;

        return Promise.reject(error);
      }

      return jsonResponse;
    });
  }, [apiKey]);

  const invite = (contactNumber: string) => {
    setError('');
    setInProgress(true);

    sendInvite(contactNumber)
      .then(() => {
        setInProgress(false);
        setCountryCode('');
        setPhoneNumber('');
        // console.log("Send-invite-success");
        onSuccess()
      })
      .catch(err => {
        console.error("err", err)
        setInProgress(false);
        setError('Unable to process invite request, Please retry again after sometime or contact Administator');
      });
  };

  const handleSubmit = () => {
    // e.preventDefault();
    let contactNumber = "+" + countryCode.trim() + phoneNumber.trim();

    if (contactNumber === "" || !phoneNumberRegex.test(contactNumber)) {
      setError("Please enter a valid phone number");
    } else {
      invite(contactNumber);
    }
  };

  return (
    <>
      <Typography variant="h6" className={classes.gutterBottom}>
        Enter details for join meeting {topic}
      </Typography>

      <Grid container spacing={1}>
        <Grid item xs={4}>
          <div className={classes.passcodeContainer}>
            <InputLabel shrink htmlFor="input-countrycode">
              Country Code
            </InputLabel>
            <TextField
              id="input-countrycode"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setCountryCode(e.target.value)}
              type="text"
              variant="outlined"
              size="small"
              fullWidth={true}
              value={countryCode}
              InputProps={{
                startAdornment: <InputAdornment position="start">+</InputAdornment>,
              }}
            />
          </div>
        </Grid>
        <Grid item xs={8}>
          <div className={classes.passcodeContainer}>
            <InputLabel shrink htmlFor="input-phonenumber">
              Phone Number
            </InputLabel>
            <TextField
              id="input-phonenumber"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value)}
              type="text"
              variant="outlined"
              size="small"
              fullWidth={true}
              value={phoneNumber}
            />
          </div>
        </Grid>
        <div>
          {error && (
            <Typography variant="caption" className={classes.errorMessage}>
              <ErrorOutlineIcon />
              {error}
            </Typography>
          )}
        </div>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          type="button"
          disabled={inProgress || countryCode == "" || phoneNumber == ""}
          onClick={(inProgress || countryCode == "" || phoneNumber == "") ? () => { } : () => handleSubmit()}
          className={classes.submitButton}
        >
          Call Me
        </Button>
      </Grid>
    </>
  );
}
