import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CloseIcon from '../../../icons/CloseIcon';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '56px',
      // background: '#F4F4F6',
      // borderBottom: '1px solid #E4E7E9',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 1em',
    },
    text: {
      color: '#10416D',
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    closeWindow: {
      cursor: 'pointer',
      display: 'flex',
      background: 'transparent',
      border: '0',
      padding: '0.4em',
    },
  })
);

export default function ParticipantWindowHeader({ closeWindow }: any) {
  const classes = useStyles();
  // const { setIsParticipantWindowOpen } = useParticipantWindowContext();

  return (
    <div className={classes.container}>
      <div className={classes.text}>Participants</div>
      <button className={classes.closeWindow} onClick={() => closeWindow()}>
        <CloseIcon />
      </button>
    </div>
  );
}
