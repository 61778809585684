import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Link, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    troubleLink: {
      cursor: 'pointer',
      padding: '10px 0px',
    },
  })
);

const TroubleshootButton: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      {process.env.REACT_APP_DIAGNOSTICS_URL &&
        <Tooltip title="Having trouble with joining meeting? Troubleshoot your connection." >
          <Link href={process.env.REACT_APP_DIAGNOSTICS_URL} target='_blank' rel="noopener noreferrer" className={classes.troubleLink} >
            Troubleshoot
          </Link>
        </Tooltip >
      }
    </>
  );

};

export default TroubleshootButton;
