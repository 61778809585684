import React, { PropsWithChildren, ChangeEvent, useState, useCallback, useEffect, useRef, useContext } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';

import Grid from '@material-ui/core/Grid';
import { InputLabel, Theme } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import Snackbar from '../Snackbar/Snackbar';

import { useAppState } from '../../state';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import useVoiceContext from '../../hooks/useVoiceContext/useVoiceContext';

import { Device, Call } from '@twilio/voice-sdk';

import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { SocketContext } from '../../utils/socket';

const phoneNumberRegex = new RegExp(/^\+[0-9]+$/);

const useStyles = makeStyles((theme: Theme) => ({
  textContainer: {
    minHeight: '70px',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
  modelAction: {
    justifyContent: 'center',
  },
}));

interface DialerDialogProps {
  open: boolean;
  onClose(event: any, reason: string): void;
}

interface CallStatusProps {
  getCallStatus: string;
}

function DialerDialog({ open, onClose }: PropsWithChildren<DialerDialogProps>) {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [call, setCall] = useState<Call | null>(null);
  const [isCallInProgress, setCallInProgress] = useState(false);
  const socket = useContext(SocketContext);

  // const callButtonRef = useRef<HTMLButtonElement>(null);
  // const disconnectCallButtonRef = useRef<HTMLButtonElement>(null);
  // const forwardCallButtonRef = useRef<HTMLButtonElement>(null);

  const { meeting } = useAppState();
  const { meeting_id } = meeting;

  const { deviceClient } = useVoiceContext();

  // const onChangeNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setPhoneNumber(event.target.value);

  //   if (!event.target.value.length && callButtonRef.current) {
  //     callButtonRef.current.disabled = true;
  //   }
  // }

  useEffect(() => {
    // console.log("DialerDialog::OPEN", open);
    setError('');
    setPhoneNumber('');
    if (open) {
      console.log('DialerDialog::OPEN::deviceClient', deviceClient);
    }
  }, [open]);

  useEffect(() => {
    socket.on('callStatus', (data: CallStatusProps) => {
      if (data?.getCallStatus === 'in-progress' || data?.getCallStatus === 'answered') {
        setCallInProgress(true);
      }
    });
    return () => {
      socket.off('callStatus');
    };
  }, [socket]);
  // const sendInvite = useCallback((phoneNumber: string) => {

  //   const api_key = window.sessionStorage.getItem('m_key');
  //   const m_key = api_key ? api_key : "";

  //   return fetch(`${process.env.REACT_APP_API_BASE_URL}/internal/invite/number`, {
  //     method: 'POST',
  //     headers: {
  //       'content-type': 'application/json',
  //       'X-API-KEY': m_key
  //     },
  //     body: JSON.stringify({
  //       phoneNumber: phoneNumber,
  //       meeting_id: meeting_id
  //     }),
  //   }).then(async res => {
  //     const jsonResponse = await res.json();
  //     console.log("jsonResponse", jsonResponse);

  //     if (!res.ok) {
  //       const error = new Error(jsonResponse.error?.message || 'There was an error while sending invitation.');
  //       error.code = jsonResponse.error?.code;

  //       return Promise.reject(error);
  //     }

  //     return jsonResponse;
  //   });
  // }, []);

  // const invite = () => {
  //   setError('');
  //   setInProgress(true);
  //   sendInvite(phoneNumber)
  //     .then(() => {
  //       setIsSuccess(true);
  //       setPhoneNumber('');
  //       setInProgress(false);
  //       onClose();
  //     })
  //     .catch(err => {
  //       console.log("err", err)
  //       setInProgress(false);
  //       setError('Unable to process invite request, Please retry again after sometime or contact Administator');
  //     });
  // };

  const callHangup = () => {
    if (call) {
      call.disconnect();
    }
  };

  const connectCallToMeeting = async () => {
    setError('');
    if (call) {
      const { parameters, customParameters } = call;
      const { CallSid } = parameters;
      const toNumber = customParameters ? customParameters.get('To') : '';
      const meeting_id = customParameters ? customParameters.get('meeting_id') : '';

      const body = {
        toNumber,
        meetingId: meeting_id,
        callSid: CallSid,
      };

      const api_key = window.sessionStorage.getItem('m_key');
      const m_key = api_key ? api_key : '';

      setRequestInProgress(true);

      try {
        const redirectResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/internal/redirect/meeting`, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            'X-API-KEY': m_key,
          },
          body: JSON.stringify(body),
        });
        setRequestInProgress(false);

        if (redirectResponse.ok) {
          const jsonResponse = await redirectResponse.json();
          console.log('jsonResponse', jsonResponse);
        } else {
          console.log('redirectResponse', redirectResponse);
          setError('Unable to connect call to the meeting.');
        }
      } catch (error) {
        setRequestInProgress(false);
        console.log('error', error);
        setError('Unable to connect call to the meeting.');
      }
    } else {
      setError('Unable to connect call to the meeting.');
    }
  };

  useEffect(() => {
    // if (callButtonRef.current) {
    //   callButtonRef.current.disabled = true;
    // }
    // if (disconnectCallButtonRef.current) {
    //   disconnectCallButtonRef.current.disabled = true;
    // }
    // if (forwardCallButtonRef.current) {
    //   forwardCallButtonRef.current.disabled = true;
    // }

    return () => {
      console.log('DialerDialog::CLOSE::call', call);
      if (call) {
        call.off('accept', updateUIAcceptedOutgoingCall);
        call.off('disconnect', updateUIDisconnectedOutgoingCall);
        call.off('cancel', updateUIDisconnectedOutgoingCall);
      }
    };
  }, []);

  const makeOutgoingCall = async () => {
    setError('');

    let params = {
      To: phoneNumber,
      meeting_id: meeting_id,
      dial_out_number: '',
    };

    if (deviceClient) {
      const deviceClientCall = await deviceClient.connect({ params });

      deviceClientCall.on('accept', updateUIAcceptedOutgoingCall);
      deviceClientCall.on('disconnect', updateUIDisconnectedOutgoingCall);
      deviceClientCall.on('cancel', updateUIDisconnectedOutgoingCall);

      setCall(deviceClientCall);
    } else {
      setError('Unable to make call.');
    }
  };

  const updateUIAcceptedOutgoingCall = () => {
    console.log('ON:: ACCEPT');
    //setCallInProgress(true);
    // callButtonRef.current!.disabled = true;
    // disconnectCallButtonRef.current!.disabled = false;
    // forwardCallButtonRef.current!.disabled = false;

    // callButtonRef.current!.setAttribute("disabled", "true");
    // disconnectCallButtonRef.current!.removeAttribute("disabled");
    // forwardCallButtonRef.current!.removeAttribute("disabled");
  };

  const updateUIDisconnectedOutgoingCall = () => {
    console.log('ON:: DISCONNECT / CANCEL');
    setCallInProgress(false);
    // callButtonRef.current!.disabled = false;
    // disconnectCallButtonRef.current!.disabled = true;
    // forwardCallButtonRef.current!.disabled = true;

    // callButtonRef.current!.removeAttribute("disabled");
    // disconnectCallButtonRef.current!.setAttribute("disabled", "true");
    // forwardCallButtonRef.current!.setAttribute("disabled", "true");
    setCall(null);
    onClose({}, '');
  };

  const handleDialButton = () => {
    if (phoneNumber.trim() === '' || !phoneNumberRegex.test(phoneNumber)) {
      setError('Please enter a valid phone number');
    } else {
      makeOutgoingCall();
    }
  };

  const handleCloseButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (call) {
      call.disconnect();
    }
    setCall(null);
    onClose(e, '');
  };

  return (
    <>
      <Snackbar
        variant="info"
        headline="Success"
        message="Call invitation sent..."
        open={isSuccess}
        handleClose={() => setIsSuccess(false)}
      />
      <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs" disableEscapeKeyDown={true}>
        <DialogTitle>Invite Participant Using Phone Number</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container justifyContent="space-between">
            <Grid item xs={12}>
              <div className={classes.textContainer}>
                <InputLabel shrink htmlFor="input-meetingId">
                  Phone Number
                </InputLabel>
                <TextField
                  id="input-phonenumber"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value)}
                  type="text"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  placeholder="Enter phone number with country code, e.g. +48111222333"
                  disabled={isCallInProgress}
                />
              </div>
            </Grid>
            <div>
              {error && (
                <Typography variant="caption" className={classes.errorMessage}>
                  <ErrorOutlineIcon />
                  {error}
                </Typography>
              )}
            </div>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions className={classes.modelAction}>
          <IconButton
            id="callButton"
            color="primary"
            type="button"
            disabled={!phoneNumber.length || isCallInProgress}
            className={classes.submitButton}
            onClick={handleDialButton}
            title="Initiate Call"
            // ref={callButtonRef}
          >
            <CallIcon />
          </IconButton>

          <IconButton
            id="hangupButton"
            color="secondary"
            type="button"
            disabled={!isCallInProgress}
            className={classes.submitButton}
            onClick={callHangup}
            title="Disconnect Call"
            // ref={disconnectCallButtonRef}
          >
            <CallEndIcon />
          </IconButton>

          <IconButton
            id="callForwardButton"
            color="primary"
            type="button"
            disabled={!isCallInProgress || requestInProgress}
            className={classes.submitButton}
            onClick={connectCallToMeeting}
            title="Connect Call To Meeting"
            // ref={forwardCallButtonRef}
          >
            <PhoneForwardedIcon />
          </IconButton>

          <IconButton
            id="cancelButton"
            color="secondary"
            type="button"
            className={classes.submitButton}
            onClick={e => handleCloseButton(e)}
            title="Cancel"
          >
            <HighlightOffIcon />
          </IconButton>

          {/* <Button
            variant="contained"
            color="secondary"
            type="button"
            className={classes.submitButton}
            onClick={onClose}
          >
            <HighlightOffIcon />
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialerDialog;
