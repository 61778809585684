import React, { ChangeEvent, FormEvent } from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';
import { useAppState } from '../../../state';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  captionFont: {
    fontWeight: 'bold',
    fontSize: '0.64rem',
    color: 'red'
  }
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  topicName: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({ name, roomName, setName, setRoomName, handleSubmit, topicName }: RoomNameScreenProps) {
  const classes = useStyles();
  const { user, meeting } = useAppState();

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  // console.log("Room-name --- MEETING--",meeting);

  let hasUsername = user?.displayName ? true : false;

  if (meeting && Boolean(meeting.isChaperoning) === true) {
    setName('Chaperoning Mode');
    hasUsername = true;
  }
  // else {
  //   if (meeting && meeting && meeting.is_anonymous) {
  //     setName('Anonymous Participant');
  //     hasUsername = true;
  //   }
  // }


  // const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        {topicName ? `Join ${topicName}` : roomName ? `Join ${roomName}` : 'Join a Meeting'}
        {(meeting && Boolean(meeting.isChaperoning) === true) ? ' (Chaperoning Mode)' : "" }
      </Typography>
      <Typography variant="body1">
        {/* {hasUsername
          ? "Enter the name of a room you'd like to join."
          : "Enter your name and the name of a room you'd like to join"} */}
        {/* {roomName
          ? "Enter your name."
          : hasUsername
            ? "Enter the Meeting ID you'd like to join."
            : "Enter your Name and the Meeting ID you'd like to join"} */}
        {roomName
          ? hasUsername
            ? ""
            : "Enter your name."
          : hasUsername
            ? "Enter the Meeting ID you'd like to join."
            : "Enter your Name and the Meeting ID you'd like to join"
        }
      </Typography>
      {meeting?.is_anonymous && <Typography variant="caption" className={classes.captionFont}>
        (Please note that any name added below will not be visible to the other participants of this call.)
      </Typography>}
      <form onSubmit={handleSubmit}>
        <div className={classes.inputContainer}>
          {!hasUsername && (
            <div className={classes.textFieldContainer}>
              <InputLabel shrink htmlFor="input-user-name">
                Your Name
              </InputLabel>
              <TextField
                id="input-user-name"
                variant="outlined"
                fullWidth
                size="small"
                value={name}
                onChange={handleNameChange}
              />
            </div>
          )}
          {!roomName && (<div className={classes.textFieldContainer}>
            <InputLabel shrink htmlFor="input-room-name">
              Meeting ID
            </InputLabel>
            <TextField
              autoCapitalize="false"
              id="input-room-name"
              variant="outlined"
              fullWidth
              size="small"
              value={roomName}
              onChange={handleRoomNameChange}
            />
          </div>
          )}
        </div>
        <Grid container justifyContent="flex-end">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!name || !roomName}
            className={classes.continueButton}
          >
            Continue
          </Button>
        </Grid>
      </form>
    </>
  );
}
