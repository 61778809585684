import React from 'react';
const css = String.raw;

export default function ChatIcon() {
  return (
    // <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M0 1C0 0.723858 0.223858 0.5 0.5 0.5H18.5C18.7761 0.5 19 0.723858 19 1V13C19 13.2761 18.7761 13.5 18.5 13.5H9.3096L6.32421 16.361C6.1796 16.4996 5.96624 16.5385 5.78202 16.4599C5.59779 16.3813 5.47826 16.2003 5.47826 16V13.5H0.5C0.223858 13.5 0 13.2761 0 13V1ZM1 1.5V12.5H5.97826C6.2544 12.5 6.47826 12.7239 6.47826 13V14.8283L8.76274 12.639C8.85583 12.5498 8.97977 12.5 9.1087 12.5H18V1.5H1Z"
    //     fill="#707578"
    //   />
    // </svg>

    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
      <style type="text/css">
        {css`
          .stChatIcon0{fill:#FFFFFF;}
          .stChatIcon1{fill:#191B1D;}
        `}
      </style>
      <g>
        <path className="stChatIcon0" d="M16,3.6C7.9,3.6,1.3,8.3,1.3,14.1c0,4,3.1,7.5,7.8,9.2c-0.9,1.9-2.3,3.5-4.2,4.7c-0.2,0.1-0.5,0.3-0.7,0.4
 c2,0.1,4.1-0.4,5.9-1.5c1.1-0.7,2.1-1.5,2.9-2.5c1,0.1,2,0.2,3.1,0.2c8.1,0,14.7-4.7,14.7-10.5S24.1,3.6,16,3.6z"/>
        <g>
          <circle className="stChatIcon1" cx="9.3" cy="14.1" r="1.7" />
          <circle className="stChatIcon1" cx="16" cy="14.1" r="1.7" />
          <circle className="stChatIcon1" cx="22.7" cy="14.1" r="1.7" />
        </g>
      </g>
    </svg>
  );
}
