import React, { useCallback, useRef } from 'react';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import VideoOffIcon from '../../../icons/VideoOffIcon';
import VideoOnIcon from '../../../icons/VideoOnIcon';

import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string }) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <Tooltip
      title={`Camera: ${!hasVideoInputDevices ? 'No Video' : isVideoEnabled ? 'Start Video' : 'Stop Video'}`}
      placement="top"
      PopperProps={{ disablePortal: true }}
      style={{ cursor: (!hasVideoInputDevices || props.disabled) ? 'not-allowed' : 'pointer' }}
    >
      <span>
        <Button
          className={props.className}
          onClick={toggleVideo}
          disabled={!hasVideoInputDevices || props.disabled}
          startIcon={isVideoEnabled ? <VideoOnIcon className="videoOnIcon" /> : <VideoOffIcon />}
        >
          {/* {!hasVideoInputDevices ? 'No Video' : isVideoEnabled ? 'Stop Video' : 'Start Video'} */}
        </Button>
      </span>
    </Tooltip>
  );
}
