import React, { createContext, useEffect, useRef, useState } from 'react';

type ParticipantWindowContextType = {
  isParticipantWindowOpen: boolean;
  setIsParticipantWindowOpen: (isParticipantWindowOpen: boolean) => void;
};

export const ParticipantWindowContext = createContext<ParticipantWindowContextType>(null!);

export const ParticipantWindowProvider: React.FC = ({ children }) => {
  const isParticipantWindowOpenRef = useRef(false);
  const [isParticipantWindowOpen, setIsParticipantWindowOpen] = useState(false);

  useEffect(() => {
    isParticipantWindowOpenRef.current = isParticipantWindowOpen;
  }, [isParticipantWindowOpen]);

  return (
    <ParticipantWindowContext.Provider
      value={{ isParticipantWindowOpen, setIsParticipantWindowOpen }}
    >
      {children}
    </ParticipantWindowContext.Provider>
  );
};
